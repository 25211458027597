/**
 * This component build the booking form address section and handle all the address cases
 * Existing address and new address
 * TODO: Any change into interface and file name (-)
 */
import { Component, Input, OnInit, ViewEncapsulation, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, OnChanges, SimpleChange } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
// Interface
import { Address } from 'src/app/Interfaces';
// Services
import { BkngFormServ, InitServ, LoaderServ, NgOnDestroy, UtilServ } from 'src/app/Services';
@Component({
	selector: 'bk-address',
	templateUrl: './Address.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class AddressComponent implements OnInit, OnChanges {

	// Variables
	@Input() currentUser: any;
	@Input() isQuote : any;
	@Input() settings: any;
	@Input() bookingType: string = '';
	@Input() addressForm!: FormGroup
	@Input() prefilledData: any;
	@Input() locationLayout: any;
	@Input() section: any;
	@Input() custSection: any;
	@Input() isMultiStepForm: boolean = false;
	@Input() locationType: any;
	@Input() isCustomerDetails: boolean = false;
	@Input() selectedLocation: any;
	@Input() pageSett: any;
	@Input() isCustomerAllowedRes: any;
	@Input() zipcode: any;
	@Input() addresses: any;
	@Output() zipCodeChange: EventEmitter<any> = new EventEmitter();

	splitAddr: boolean = false;
	addrType: any;
	address: any;
	selectedAddr: any;
	isAddrDisabled: any;
	isRadioDisabled: any;
	isAddrReadonly: boolean = false;
	isShowZipcode: boolean = false;
	loaderId: string = 'bkng-address';

	// convenience getter for easy access to form fields
	get customerGroup(): FormGroup{
		return this.addressForm.controls['customer'] as FormGroup;
	}

	constructor(
		private cDRef: ChangeDetectorRef,
		public utilServ: UtilServ,
		public initServ: InitServ,
		private loaderServ: LoaderServ,
		private bkngFormServ: BkngFormServ
	) {}


	ngOnInit(): void {
		// Build address section
		this.buildAddr();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(!changes){
			return;
		}
		for(let propName in changes) {
			let change = changes[propName];
			if (this.isAddrsUpdated(change, propName) && JSON.stringify(change.currentValue) != JSON.stringify(change.previousValue)) {
				// Build address section after props value change
				this.buildAddr();
				break;
			}
		}
	}

	/**
	 * Checks if the 'addresses' property has been updated after the initial change.
	 * @param change - The SimpleChange object containing previous and current values.
	 * @param propName - The name of the property to check.
	 * @returns True if the property name is 'addresses' and it's not the first change.
	 */
	private isAddrsUpdated(change: SimpleChange, propName: string): boolean {
		return !change.firstChange && propName === 'addresses';
	}

	/**
	 * Builds the address details for the booking flow.
	 * - Shows a loader during the process.
	 * - Splits and sets address parts.
	 * - If the booking type is 'reschedule' and location type is 'SA',
	 *   sets the address type to 'existing_address' and fetches user addresses.
	 * - Otherwise, sets the address based on the customer type.
	 * - Makes the address editable.
	 * - Triggers change detection and hides the loader once done.
	 */
	private buildAddr(): void {
		this.loaderServ.show(this.loaderId);
		// Set split address based on section design id
		this.setSplitAddr();
		if(this.bookingType == 'reschedule'){
			if(this.locationType == 'SA'){
				this.addrType = "existing_address";
				this.userAddrs();
			}
		} else {
			this.setAddrBasedOnCustomerType();
		}
		this.setAddrEditableFields();
		this.cDRef.detectChanges();
		this.loaderServ.hide(this.loaderId);
	}

	/**
	 * Determines whether the address should be split into parts based on the customer section design.
	 * - If the design ID is 'bk_customer_v2' or 'bk_customer_v4', enables splitAddress mode.
	 */
	private setSplitAddr(): void {
		if(this.custSection?.design_id == 'bk_customer_v2' || this.custSection?.design_id == 'bk_customer_v4'){
			this.splitAddr = true;
		}
	}

	/**
	 * Handles user addresses based on availability and booking type.
	 * - If addresses are available, prefill the address fields.
	 * - If no addresses and booking type is 'add' or 'draft', set address type to 'new_address' and reset address fields.
	 * - Sets the quote address and triggers change detection.
	 */
	private userAddrs(): void {
		console.log(this.addresses, 'this.addresses----------------');
		if(this.addresses){
			this.prefilledAddr();
		} else if(['add', 'draft'].includes(this.bookingType)){
			this.addrType = 'new_address';
			this.resetAddr();
		}
		this.setQuoteAddr();
		this.cDRef.detectChanges();
	}

	/**
	 * Prefills the address based on the booking type and prefilled data.
	 * If the booking type is 'reschedule', it calls the method to prefill the reschedule address.
	 * Otherwise, it checks if there is prefilled address data and sets the address type accordingly.
	 * If no prefilled address ID is present, it defaults to a new address and selects the first available address.
	 * If prefilled data is not available, it selects the first address from the list.
	 * Finally, it triggers change detection to update the view.
	 */
	private prefilledAddr(): void {
		if(this.bookingType == 'reschedule'){
			this.prefilledReschAddr();
		} else if(this.prefilledData?.address?.address){
			if(!this.prefilledData.address_id){
				this.addrType = 'new_address';
				this.setAddrEditableFields();
				if(this.utilServ.checkArrLength(this.addresses)){
					this.selectedAddr = this.addresses[0];
				}
			} else if(this.utilServ.checkArrLength(this.addresses)){
				this.prefilledAddOrDraftAddr();
			}
		} else {
			this.selectedAddr = this.addresses[0];
			this.setSelectedAddr(this.selectedAddr);
		}
		this.cDRef.detectChanges();
	}

	/**
	 * Prefills the address for rescheduled bookings.
	 * - Checks if there are existing addresses.
	 * - If an address matches the prefilled data's address ID, selects that address and sets it as the selected address.
	 */
	private prefilledReschAddr(): void {
		if(this.utilServ.checkArrLength(this.addresses)){
			for(let address of this.addresses){
				if(address.id == this.prefilledData.address.id){
					this.selectedAddr = address;
					this.setSelectedAddr(this.selectedAddr);
					break;
				}
			}
		}
	}

	/**
	 * Sets the selected address details into the form controls.
	 * - Updates customerGroup and addressForm with the selected address data.
	 * - Handles both full address and short address display based on splitAddr flag.
	 * - Updates latitude/longitude and triggers zip code change event.
	 * @param selectedAddr - The address object selected by the user.
	 */
	public setSelectedAddr(selectedAddr: any): void {
		if(!selectedAddr){
			return;
		}
		let {id, address = '', zipcode = null, state = '', city = '', apt = null, short_address = '', latlng = null} = selectedAddr;
		this.customerGroup.patchValue({
			address_id: id,
			address,
			customer_zipcode: zipcode,
			state,
			city,
			apt
		})
		let addrGroup: FormGroup = this.addressForm.controls['address'] as FormGroup;
		this.updateShortAddrCtrl(short_address, address);
		this.address = this.splitAddr && short_address ? short_address : address;
		this.addressForm.controls['address_id'].setValue(id)
		addrGroup.patchValue({
			address,
			zipcode,
			state,
			city,
			apt
		});
		this.updateLatLongitudeCtrl(latlng);
		this.zipCodeChange.emit(zipcode);
	}

	/**
	 * Updates the 'short_address' control in both addressForm and customerGroup.
	 * - If a short_address is provided, it sets that value.
	 * - Otherwise, it falls back to using the full address.
	 * @param short_address - The short version of the address (optional).
	 * @param address - The full address (optional).
	 */
	private updateShortAddrCtrl(short_address: string = '', address: string = ''): void {
		let addrGroup: FormGroup = this.addressForm.controls['address'] as FormGroup;
		if(short_address){
			addrGroup.controls['short_address'].setValue(short_address);
			this.customerGroup.controls['short_address'].setValue(short_address);
		} else{
			addrGroup.controls['short_address'].setValue(address);
			this.customerGroup.controls['short_address'].setValue(address);
		}
	}

	/**
	 * Updates the 'latlng' control inside the address form group with latitude and longitude values.
	 * - If latlng is provided, it sets 'lat' and 'lng' values.
	 * - Defaults to 0 if lat or lng is missing.
	 * @param latlng - Object containing latitude and longitude.
	 */
	private updateLatLongitudeCtrl(latlng: any): void{
		let addrGroup: FormGroup = this.addressForm.controls['address'] as FormGroup;
		if(latlng){
			addrGroup.controls['latlng'].patchValue({
				lat: latlng?.lat ? latlng.lat : 0,
				lng: latlng?.lng ? latlng.lng : 0
			});
		}
	}

	/**
	 * Updates the editable state of address-related fields.
	 * - Checks if the address input fields should be disabled.
	 * - Checks if the address selection radio buttons should be disabled.
	 */
	private setAddrEditableFields(): void {
		this.isAddrDisabled = this.addrDisable();
		this.isRadioDisabled = this.addrRadioDisable();
	}

	/**
	 * Determines if the address input fields should be disabled.
	 * - Disables fields if the address type is not 'new_address' and booking is completed or lacks zipcode during reschedule.
	 * - Also disables fields if the address type is 'existing_address'.
	 * - Sets 'isAddrReadonly' flag accordingly.
	 * @returns 'disabled' if the fields should be disabled, otherwise null.
	 */
	private addrDisable(): string | null{
		if(this.isBkngCompletedOrNoZipcodeAndCustomerResch() || this.addrType == "existing_address"){
			this.isAddrReadonly = true;
			return 'disabled';
		}
		this.isAddrReadonly = false;
		return null;
	}

	/**
	 * Checks if the booking is completed or lacks a zipcode during customer reschedule.
	 * - Returns true if:
	 *   - The booking status is in the completed/charged statuses, OR
	 *   - The zipcode field is empty,
	 * AND
	 *   - The customer is not allowed to reschedule.
	 * @returns boolean indicating whether the address should be non-editable due to booking status or missing zipcode.
	 */
	private isBkngCompletedOrNoZipcodeAndCustomerResch(): boolean {
		return this.addrType != 'new_address' && ((this.bkngFormServ.completedChargedStatuses.includes(this.prefilledData?.status) || (!this.addressForm.controls['zipcode'].value)) && this.isCustomerAllowedRes == false)
	}

	/**
	 * Determines if the address radio button should be disabled.
	 * - Disables if booking type is 'reschedule' and status is completed/charged.
	 * @returns 'disabled' | null
	 */
	private addrRadioDisable(): string | null {
		if(this.bookingType == 'reschedule' && this.bkngFormServ.completedChargedStatuses.includes(this.prefilledData?.status)){
			return 'disabled';
		}
		return null;
	}

	/**
	 * Prefills the address based on valid address ID or valid address data.
	 */
	private prefilledAddOrDraftAddr(): void {
		for(let address of this.addresses){
			if(this.isAddrValid(address)){
				this.selectedAddr = address;
				this.setSelectedAddr(this.selectedAddr);
				break;
			}
		}
	}

	/**
	 * Checks if the given address matches the prefilled data.
	 * - If an address ID exists in prefilled data, compares by ID.
	 * - Otherwise, compares by address string.
	 * @param addr - The address object to validate.
	 * @returns true if the address matches the prefilled data, otherwise false.
	 */
	private isAddrValid(address: Address): boolean {
		return this.prefilledData.address_id
			? address.id === this.prefilledData.address_id
			: address.address === this.prefilledData.address?.address;
	}

	/**
	 * Resets the customer and address form groups to their default/empty state.
	 * - Clears all address-related fields in both customerGroup and addressForm.
	 * - Empties latitude and longitude values.
	 * - Marks the customer address field as untouched.
	 * - Resets local address variable and re-evaluates field editability.
	 */
	private resetAddr(): void {
		this.customerGroup.patchValue({
			address_id : null,
			address : null,
			customer_zipcode : null,
			state : null,
			city : null,
			apt : null,
			short_address : null
		});
		this.addressForm.controls['address_id'].setValue('');
		let addrGroup: FormGroup = this.addressForm.controls['address'] as FormGroup;
		addrGroup.patchValue({
			address: '',
			zipcode: '',
			state: '',
			city: '',
			apt: '',
			short_address: ''
		});
		// empty the latitude and longitude
		this.setLatlng();
		this.customerGroup.controls['address'].markAsUntouched();
		this.address = null;
		this.setAddrEditableFields();
	}

	/**
	 * Sets the address type to 'new_address' and updates the selected address
	 * if the current booking is a quote and the customer's address differs from the prefilled address.
	 * This function is executed after a delay to ensure any asynchronous operations are completed.
	 */
	private setQuoteAddr(): void {
		setTimeout(()=>{
			if(!(this.isQuoteAndAddr() && this.isCustomerAddrDiffersFromPrefilledAddr())){
				return;
			}
			this.addrType = 'new_address';
			this.addrTypeChange('new');
			this.setSelectedAddr(this.prefilledData?.address);
		}, 100);
	}

	/**
	 * Checks if the current booking is a quote and if there is prefilled address data available.
	 * @returns {boolean} - Returns true if the booking is a quote and prefilled address data exists, otherwise false.
	 */
	private isQuoteAndAddr(): boolean {
		return this.isQuote && this.prefilledData?.address;
	}

	/**
	 * Checks if the customer's selected address differs from the prefilled address.
	 * - Returns true if there is no prefilled address ID.
	 * - Or if customer data exists, and the customer's address ID is different from the prefilled address ID.
	 */
	private isCustomerAddrDiffersFromPrefilledAddr(): boolean {
		return !this.prefilledData?.address_id || (this.prefilledData?.customer && this.prefilledData?.address_id && (this.prefilledData.address_id !== this.prefilledData.customer?.address_id))
	}

	/**
	 * Sets the address type and fetches user addresses based on the customer type.
	 * - If the customer is an existing customer, sets address type to "existing_address" and loads user addresses.
	 * - If the customer is new, sets address type to "new_address" and assigns the address from the form if available.
	 */
	private setAddrBasedOnCustomerType(): void {
		// Existing customer
		if(this.customerGroup.controls['customer_type'].value == 'existing customer'){
			this.addrType = 'existing_address';
			this.userAddrs();
			return;
		}
		this.addrType = 'new_address';
		let formGroup: any = this.addressForm.controls['address'] as FormGroup;
		if(formGroup && formGroup.value?.['address']){
			this.address = formGroup.value['address'];
		}
	}

	/**
	 * Handles address input from the user.
	 * - Resets latitude and longitude values.
	 * - Updates the address in both addressForm and customerGroup.
	 * - Marks the address field as touched if it is empty.
	 * - If 'apt' value is present in customerGroup, it is also set in addressForm.
	 * @param place - The address input event containing the user's input value.
	 */
	public getAddr(place: any): void {
		// Empty the latitude and longitude
		this.setLatlng();
		this.address = place.target.value;
		let addrGroup: FormGroup = this.addressForm.controls['address'] as FormGroup;
		addrGroup.controls['address'].setValue(this.address);
		if(!this.address){
			this.customerGroup.controls['address'].markAsTouched();
		}
		this.customerGroup.controls['address'].setValue(this.address);
		if(this.customerGroup.controls['apt']?.value){
			addrGroup.controls['apt'].setValue(this.customerGroup.controls['apt'].value);
		}
	}

	/**
	 * Generates and sets the short version of the provided address.
	 * - Uses util service to create a short address format.
	 * - Updates both customerGroup and addressForm with the short address.
	 * - If 'splitAddress' flag is enabled, updates the main 'address' property as well.
	 * @param addr - The complete address object to generate a short address from.
	 */
	private createShortAddr(address: any): void {
		let shortAddr: any = this.utilServ.createShortAddr(address);
		if(this.splitAddr){
			this.address = shortAddr;
		}
		this.customerGroup.controls['short_address'].setValue(shortAddr);
		let addrGroup: FormGroup = this.addressForm.controls['address'] as FormGroup;
		addrGroup.controls['short_address'].setValue(shortAddr);
	}

	/**
	 * Sets specific parts of the address based on the provided type.
	 * - Supports updating 'zipcode', 'city', and 'state'.
	 * - Updates both addressForm and customerGroup controls.
	 * - Handles validators dynamically for zipcode.
	 * @param addr - The full address object.
	 * @param type - The type of address field to update ('zipcode' | 'city' | 'state').
	 */
	public setAddr(address: any, type: string): void{
		let addrGroup: FormGroup = this.addressForm.controls['address'] as FormGroup;
		switch(type){
			case 'zipcode': {
					// Set the latitude and longitude
					this.setLatlng(address);
					this.createShortAddr(address);
					let zipcode: string = this.getLongAddr(this.utilServ.getComponentByType(address, 'postal_code'));
					addrGroup.controls['zipcode'].setValue(zipcode);
					this.customerGroup.controls['customer_zipcode'].setValue(zipcode);
					this.customerGroup.controls['customer_zipcode'].markAsUntouched();
					if(!zipcode && this.isLayoutNoZipcode()) {
						this.isShowZipcode = true;
						this.customerGroup.controls['customer_zipcode'].setValidators([Validators.required]);
					} else {
						this.isShowZipcode = false;
						this.customerGroup.controls['customer_zipcode'].clearValidators();
					}
					this.customerGroup.controls['customer_zipcode'].updateValueAndValidity();
					this.zipCodeChange.emit(zipcode);
				}
			break;
			case 'city': {
				let selectedCity: string = this.getLongAddr(this.utilServ.getComponentByType(address, 'locality'));
				addrGroup.controls['city'].setValue(selectedCity);
				this.customerGroup.controls['city'].setValue(selectedCity);
			}
			break;
			case 'state': {
				let selectedState: string = this.getLongAddr(this.utilServ.getComponentByType(address, 'administrative_area_level_1'))
				addrGroup.controls['state'].setValue(selectedState);
				this.customerGroup.controls['state'].setValue(selectedState);
			}
			break;
		}
	}

	/**
	 * Checks if the current location layout does not require a zipcode.
	 * Returns true if the layout is defined and is not 'zipcode_based'.
	 */
	private isLayoutNoZipcode(): boolean {
		return this.locationLayout && this.locationLayout != 'zipcode_based';
	}

	/**
	 * Retrieves the long name from an address component.
	 * Returns the 'long_name' property if it exists, otherwise returns an empty string.
	 */
	private getLongAddr(code: any): any {
		return code?.long_name || '';
	}

	/**
	 * Synchronizes the address form values with the customer form values
	 * when the input field loses focus, based on the address type.
	 * @param type - The type of address field ('zipcode', 'city', 'state')
	 */
	public focusOutAddr(type: string): void {
		let addrGroup: any = this.addressForm.controls['address'] as FormGroup;
		switch(type){
			case 'zipcode':
				addrGroup.controls['zipcode'].setValue(this.customerGroup.controls['customer_zipcode'].value);
				this.zipCodeChange.emit(this.customerGroup.controls['customer_zipcode'].value);
			break;
			case 'city':
				addrGroup.controls['city'].setValue(this.customerGroup.controls['city'].value);
			break;
			case 'state':
				addrGroup.controls['state'].setValue(this.customerGroup.controls['state'].value);
			break;
		}
	}

	/**
	 * Handles changes in the address type selection.
	 * Resets or pre-fills the address fields based on the selected type,
	 * clears zipcode validators, updates field states, and triggers change detection.
	 * @param type - The selected address type ('existing' or others)
	 */
	public addrTypeChange(type: string): void {
		this.isShowZipcode = false;
		this.customerGroup.controls['customer_zipcode'].markAsUntouched();
		this.customerGroup.controls['customer_zipcode'].clearValidators();
		this.customerGroup.controls['customer_zipcode'].updateValueAndValidity();
		if(type == 'existing'){
			this.setSelectedAddr(this.selectedAddr);
		} else{
			this.resetAddr();
		}
		this.setAddrEditableFields();
		this.cDRef.detectChanges();
	}

	/**
	 * Sets the latitude and longitude values in the address form group.
	 * If address geometry is provided, extracts and formats the coordinates;
	 * otherwise, defaults to 0 for both latitude and longitude.
	 * @param address - The address object containing geometry data (optional)
	 */
	private setLatlng(address: any = null): void{
		let latitude = (address?.geometry?.location) ? (address.geometry.location.lat()) : 0;
		let longitude = (address?.geometry?.location) ? (address.geometry.location.lng()) : 0;
		let addrGroup: FormGroup = this.addressForm.controls['address'] as FormGroup;
		addrGroup.controls['latlng'].patchValue({
			lat: Number(latitude.toFixed(6)),
			lng: Number(longitude.toFixed(6))
		});
	}
}

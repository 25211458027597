/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Component, Input, OnInit, Self, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, distinctUntilChanged } from 'rxjs';
import * as dayjs from 'dayjs';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
declare const parentToTop: any;
declare const top: any
declare const gtag : any;
// External lib
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
// Services
import { ApiServ, InitServ, LoaderServ, NgOnDestroy, UtilServ, BkngFormServ, BkngFrmCmnFunServ, BkngFormValidateServ, PopupServ, SectionServ, PaymentGatewayServ, BkngFormPriceCalServ, PrvdrPayCalServ, DiscountCalServ, BkngCustomSectionService, LeadsServ, PaymentGatewayPayload, CalCmnFuncServ, OverridePrvdrTimeServ } from '../../Services';
import { AddBkngServ } from '../index';
import { AuthServ } from '../../Core/_services'; // Auth Service
// Constants
import { IS_DEV, DEV_HOST } from '../../Constants';
// Customer details component
import { CustomerDetailsComponent, DiscountsComponent, CustomerEmailComponent, PricingParameterWithAreaComponent, ItemsWithPckgsComponent, ItemsWithAddonsComponent, ServiceProviderComponent, CustomFieldsComponent, PaymentInfoComponent } from '../../Global/BookingForm';
import { MultiServiceProviderComponent } from '../../Global/BookingForm/MultiStepComp';
// Interfaces
import { BillingAddress, TypeSlugObj } from '../../Interfaces';

@Component({
	selector: 'bk-common-add-form',
	template: '',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class CommonAddFormComponent implements OnInit {

	// Variables
	@Input() isShortFormOpen: boolean = false;
	@Input() secId: string = '';
	@Input() section: any;
	@Input() pageSett: any;
	@Input() parentPageSett: any;
	@Input() industryId: any;
	@Input() formId: any;
	@Input() couponData: any;
	@Input() couponCode: any;
	@Input() formSett: any;
	@Input() formMetaData: any;
	@Input() secSett: any;
	@Input() industrySlug: any;
	@Input() isMultiIndsFrm: boolean = true;
	@Input() noOfIndustries: any;
	@Input() popupId: string = '';
	@Output() setMultiStpFrmFrstStp: EventEmitter<any> = new EventEmitter();
	@Output() secondStepActivate : EventEmitter<any> = new EventEmitter();
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() onProviderInfoChange: EventEmitter<any> = new EventEmitter();

	@ViewChild('customerComp', {static: false}) customerComp: CustomerDetailsComponent | undefined;
	@ViewChild(DiscountsComponent) discountComp: DiscountsComponent | undefined;
	@ViewChild('customerEmailComp', {static: false}) customerEmailComp: CustomerEmailComponent | undefined;
	@ViewChild(CustomFieldsComponent) customFieldsComp: CustomFieldsComponent | undefined;
	@ViewChild('locationComp', {static: false}) locationComp: any;
	@ViewChild('emailComp', {static: false}) emailComp: any;
	@ViewChild('areaParamComp', {static: false}) areaParamComp: any;
	@ViewChild(PaymentInfoComponent) paymentInfo: PaymentInfoComponent | undefined; //Payment gatway component
	@ViewChild(PricingParameterWithAreaComponent) pricingParameterWithAreaComp : PricingParameterWithAreaComponent | undefined; //Price param with area
	@ViewChild(ItemsWithPckgsComponent) itemsWithPckgsComp : ItemsWithPckgsComponent | undefined; //Items with package
	@ViewChild(ItemsWithAddonsComponent) itemsWithAddonsComp : ItemsWithAddonsComponent | undefined; //Items with addon
	@ViewChild(ServiceProviderComponent) serviceProviderComp : ServiceProviderComponent | undefined; //Service provider
	@ViewChild(MultiServiceProviderComponent) multiServiceProviderComp : MultiServiceProviderComponent | undefined; //Multi step service provider

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	appData: any = this.initServ.appData; // App data
	BKFrm!: FormGroup;
	quoteId: any;
	poweredByLink: string = '';
	formLayout: string = 'two_step';
	locationLayout: string = 'zipcode_based';
	sectionOrder: any = {
		location:1,
		service:2,
		frequency:3,
		pricing_parameter:4,
		extras:5,
		custom_fields_step_one:6,
		email:7,
		service_provider:8,
		tip_parking:9,
		customer_details:10,
		address_details:11,
		key_info:12,
		special_notes:13,
		discounts:14,
		custom_fields_step_two:15,
		payment_info:16
	}
	sidebarSecOrder: any = {
		how_it_works: 1,
		summary: 2,
		reviews: 3,
		fb_coupon: 4,
		faq: 5
	}
	// One step form sections
	oneStepFormSections: string[] = ["location", "service", "frequency", "pricing_parameter", "extras", "custom_fields_step_one", "email", "service_provider", "tip_parking", "customer_details", "address_details", "key_info", "special_notes", "discounts", "custom_fields_step_two", "payment_info"];
	// Two step form sections
	twoStepFormSections: any = {
		step_one: ["location", "service", "frequency", "pricing_parameter", "extras", "custom_fields_step_one", "email"],
		step_two: ["service_provider", "tip_parking", "customer_details", "address_details", "key_info", "special_notes", "discounts", "custom_fields_step_two" ,"payment_info"]
	}
	// Multi step form sections
	multistepFormSteps : any = ["step_one", "step_two", "step_three", "step_four"];
	multiStepFormSections: any = {
		step_one: ["location","email","service", "frequency"],
		step_two: ["pricing_parameter", "extras", "custom_fields_step_one"],
		step_three:["service_provider"],
		step_four: ["customer_details", "address_details", "custom_fields_step_two", "special_notes", "key_info", "tip_parking", "discounts", "payment_info"]
	}
	// Summary sequence
	summarySequence: string[] = ['how_it_works', 'summary', 'reviews', 'fb_coupon', 'faq'];
	// Name of controls that apply value change event
	controlChangeNames: string[] = ['location_id', 'is_partial_cleaning', 'tip', 'items', 'parking', 'bonus', 'adjusted_time', 'adjusted_price', 'same_day_booking', 'expedited_amount', 'provider_details', 'uid'];
	//Loaders
	loaderIds: any = {
		location: 'add-bkng-location',
		frequency: 'add-bkng-frequency'
	}
	// Cookies info: ses_id,campaign_id,contact_id,track_from & sequence_id
	cookiesInfo: any;
	referrerSource : any;
	ses_id : any;
	contact_id : any;
	campaign_id : any;
	track_from: any;
	sequence_id: any;
	settings: any;
	shortFormData: any;
	widgetFormData: any;
	embededPrefilledParams: any;
	currentUser: any;
	currentUserIp: string = '';
	currentUserId: any;
	selectedLocation: any;
	selectedServiceType: any;
	selectedFrequency: any;
	isStepOneValid: boolean = false;
	bookingType: string = 'add';
	sidebarOffset: any;
	tempSidebarOffset: any;
	isQuoteEditable : boolean = true;
	isAllDataLoaded: boolean = false;
	// isAllDataLoadedTwo: boolean = false;
	prefilledData: any = null;
	buildFormParamsStatus: any = {
		pricingParams: false,
		areaParams: false,
		itemsAddons: false,
		itemPackages: false
	}
	prefilledFormParams: any = {
		pricing_param: {},
		excludes: {},
		extras: {},
		items: {},
		area_param: {}
	}
	settingsObj: any = {
		pricing_param: {},
		excludes: {},
		extras: {},
		items: {},
		package : {},
		package_addons : {},
		addons : {},
		area_param: {}
	}
	formElemCombStatus: any;
	couponPerm: boolean = true;
	dayDiscountApplyToAll : any = 'false';
	dayDiscountBookings : any;
	dayDiscounts : any;
	dateDiscountsArray : any = [];
	dayDiscountsArray : any = [];
	isLoadDailyDiscount : boolean = false;

	referralAmountUserId: any;

	// Multi steps
	isStepOneAvail: boolean = false;
	isStepAvailFormFive: boolean = false;
	activeStep: number = 0;
	serviceName: string = '';
	frequencyName: string = '';

	// Validation status variables
	isNoItemSelected: boolean = false;
	isNoPackageSelected: boolean = false;
	isNoAddonSelected: boolean = false;
	validPckgStatus: boolean = false;
	// buildCustomFields: any;
	services: any;
	paymentGatewayChild: any;
	isReadyToLoad : boolean = false;
	emailSecStatus: boolean = true;
	refreshLocComp: boolean = false; // Variables true in case of widgets
	isQuote : boolean = true;
	multiStepsHeadingStatus: any;
	zipcodeValue : any;
	// Terms and conditions
	acceptTcStatus: boolean = false;
	isAcceptTcValid: boolean = true;

	// This variable is used to build the address section
	buildAddressStatus: boolean = true;

	priceLocalVar : any = this.priceCalServ.getCmnPriceVar; // price calculation local variables
	prvdrPayVar : any = this.priceCalServ.getCmnPrvdrPayVar; // provider pay local variables
	bookingId : any = null;
	isPrvdrPayDifferent : boolean = false;
	prevPrvdrOvridePay : any;
	firstTimeStatus : boolean = true;
	availableSett : any;
	dailyDiscountQueryParam: any = null;
	taxQueryParams: any = null;
	// TODO Harleen any;
	customerDetails: any;

	// eslint-disable-next-line max-params
	constructor(public actRoute: ActivatedRoute, public apiServ: ApiServ, @Self() public destroy: NgOnDestroy, public loader: LoaderServ, public utilServ: UtilServ, public initServ: InitServ, public addBkngServ: AddBkngServ, public frmBldr: FormBuilder, public toastr: ToastrService, public router: Router, public translate: TranslateService, public cDRef: ChangeDetectorRef, public bkngFormServ: BkngFormServ, public authServ: AuthServ, public bkngCmnFun: BkngFrmCmnFunServ, public validateFrmServ: BkngFormValidateServ, private popupServ: PopupServ, private dialogRef: MatDialog, private secServ: SectionServ, private paymentServ: PaymentGatewayServ, public priceCalServ : BkngFormPriceCalServ, public prvdrPayCalServ: PrvdrPayCalServ, public dscntCalServ: DiscountCalServ, public bkngCustSecServ: BkngCustomSectionService, public calCmnFuncServ: CalCmnFuncServ, public leadsServ: LeadsServ, public overridePrvdrTimeServ: OverridePrvdrTimeServ) {
		if(this.initServ.paymentGateway){
			this.utilServ.loadPaymentGatewayScript(this.initServ.paymentGateway);
		}
		// Current login user info from browser local storage
		this.currentUser = this.utilServ.appLocalStorage();
		// Quote id & share link
		this.quoteId = this.actRoute.snapshot.queryParamMap.get('qid');
		if(this.quoteId){
			this.bookingType = 'draft';
			this.isQuote = true;
			this.prefilledData = null;
			this.getQuote();
			setTimeout(()=>{
				this.activeStep = 0;
				this.setMultiStpFrmFrstStp.emit(true);
			}, 100)
		}else{
			this.isQuote = false;
		}
		// Powered by link
		this.poweredByLink = this.utilServ.poweredByLink();
		// Coupon section permission
		this.couponPerm = this.utilServ.appPermission('coupons');
	}

	ngOnInit(): void {
		this.checkGtag()
		if(this.section){
			if(this.section.email?.id){
				this.emailSecStatus = this.secServ.checkEleStatus(this.pageSett, this.section.email.id);
			}
		}

		let industryId = this.industryId;
		if(!industryId){ industryId = 1; }
		let formId = +this.formId;
		if(!formId){ formId = 1; }
		// Short form data
		this.shortFormData = this.bkngFormServ.getShortFormData;
		this.bkngCustSecServ.prefilledData = this.bkngFormServ.getShortFormData;
		this.bkngCustSecServ.isRescFirstLoad = true;
		// Form widgets
		this.widgetFormData = this.utilServ.widgetFormData;
		// Embed prefilled params
		this.embededPrefilledParams = this.utilServ.appLocalStorage('embeded_params', true);
		// cookies & referrer url
		let cookies: any = this.utilServ.getCookieAndRefUrl();
		this.referrerSource = cookies.referrerSource; //referrer url
		this.cookiesInfo = cookies.cookiesInfo; // cookies
		this.ses_id = this.cookiesInfo.ses_id;
		this.campaign_id = this.cookiesInfo.campaign_id;
		this.contact_id = this.cookiesInfo.contact_id;
		this.track_from = this.cookiesInfo.track_from;
		this.sequence_id = this.cookiesInfo.sequence_id;
		// Set the logged user ip and id otherwise empty & default
		if(this.currentUser){
			this.currentUserIp = this.currentUser.Ip;
			this.currentUserId = this.currentUser.id;
		} else{
			this.currentUserIp = this.initServ.ipAddress;
			this.currentUserId = null;
		}
		// Build form
		this.BKFrm = this.frmBldr.group(this.addBkngServ.addBkngFormGroup(this.currentUser, industryId, formId));
		// Get the form build settings
		if(!this.quoteId && this.formId){
			this.getFormBuildSett();
		}
		// Logged in user, set the form value and get the referral discount
		if(this.currentUser && this.currentUser.id){
			this.BKFrm.controls['uid'].setValue(this.currentUser.id);
			this.BKFrm.controls['customer'].patchValue({
				id : this.currentUser.id,
				// TODO: Removed using username from local storage
				// email_id : this.currentUser.username,
				email_id : this.initServ?.userInfo?.email_id,
				customer_type : "existing customer"
			});
			this.getReferralDiscount();
		}
		// Set the draft id
		if(this.quoteId){
			this.BKFrm.controls['draft_id'].setValue(parseInt(this.quoteId));
		}
		// Value change event
		if(this.controlChangeNames && this.controlChangeNames.length > 0){
			for(let controlName of this.controlChangeNames){
				this.formControlChange(controlName);
			}
		}
		// Get custom fields
		this.getCustomFields()
		this.setAcceptTcStatus();
		this.bkngFormServ.onPriceableFieldChange.pipe(takeUntil(this.destroy)).subscribe((status: boolean) => {
			if(status){
				this.calcTotalPrice();
			}
		});
	}
	/**
	 * Form control values change event
	 * @param controlName : location_id, is_partial_cleaning, tip, items, parking, bonus, adjusted_time, adjusted_price, same_day_booking, expedited_amount, provider_details & uid
	 */
	private formControlChange(controlName: string): void {
		this.BKFrm.controls[controlName].valueChanges.pipe(distinctUntilChanged(),takeUntil(this.destroy)).subscribe(value => {
			switch(controlName) {
				case 'location_id':
					// Reset date time
					if(this.isAllDataLoaded && !this.prefilledData){
						this.resetDateTime('location', true);
					}
					if(value && this.BKFrm.controls['location_id'].value){
						let addressGroup = <FormGroup>this.BKFrm.controls['address'];
						let zipcode = addressGroup.controls['zipcode'].value;
						setTimeout(()=>{
							if(this.locationLayout && this.locationLayout != 'zipcode_based' && zipcode){
								this.reCalculateTax(zipcode);
							} else {
								this.calculateTax();
							}
						},100);
					} else{
						this.priceLocalVar.bookingTaxRate = null;
						this.priceLocalVar.bookingTaxType = '';
					}
				break;
				case 'is_partial_cleaning':
					if(this.isAllDataLoaded && !this.prefilledData){
						this.resetDateTime('amount');
					}
					this.createControlArray('partial_cleaning'); // Create excludes
					this.calcTotalPrice();
				break;
				case 'tip':
				case 'parking':
				case 'bonus':
				case 'expedited_amount':
					// TODO: Change this code due to live issue
					// We add the set time out because, it create issue of function called before the value set
					setTimeout(() => {
						this.calcTotalPrice();
					},100);
				break;
				case 'adjusted_time':
				case 'adjusted_price':
					this.calcTotalPrice();
					this.convertAdjustedTimeToHours();
				break;
				case 'same_day_booking':
					if(this.BKFrm.controls['same_day_booking'].value){
						if(this.selectedServiceType?.enable_expedited_charge && this.selectedServiceType.show_expedited_charge_text){
							// this.bkngFormServ.msgPopup(this.selectedServiceType.expedited_charge_text);
							this.bkngFormServ.newMsgPopup(this.selectedServiceType.expedited_charge_text);
						}
					}
					this.calcTotalPrice();
				break;
				case 'items':
					this.isNoItemSelected = false;
					this.isNoPackageSelected = false;
					this.isNoAddonSelected = false;
				break;
				case 'provider_details':
					this.prvdrPayVar = this.priceCalServ.getCmnPrvdrPayVar;
					this.calcTotalPrice();
				break;
				case 'uid':
					// Changes of uid every time
					if(this.formLayout == 'one_step' || this.formLayout == 'multi_step'){
						if(this.BKFrm.controls['uid'].value && this.BKFrm.controls['uid'].value != 0){
							this.onEmailChange();
						}
						this.resetDateTime('email', true);
					}
				break;
			}
		});
	}
	/**
	 * Function is called when customer is changed.
	 */
	private onEmailChange(): void {
		let couponGroup = <FormGroup>this.BKFrm.controls['coupon'];
		let giftCardGroup = <FormGroup>this.BKFrm.controls['gift_card'];
		if(couponGroup.controls['code'].value){
			this.reApplyCoupon('email');
		}else{
			if(giftCardGroup.controls['code'].value){
				this.reApplyGiftCardDiscount('email');
			}else{
				this.reApplyReferral('email');
			}
		}
		/** reset date time **/
		this.resetDateTime('email');
		this.calcTotalPrice();
	}
	/**
	 * Function to call when booking date is changed.
	 * It removed the applied coupon.
	 */
	public onBookingDateChange(): void {
		let couponGroup = <FormGroup>this.BKFrm.controls['coupon'];
		if(couponGroup.controls['code'].value || this.couponCode){
			this.reApplyCoupon('date');
		}
		this.calcTotalPrice();
	}
	/**
	 * Function to call on spot change.
	 */
	public onSpotChange(): void {
		let giftCardGroup = <FormGroup>this.BKFrm.controls['gift_card'];
		if(giftCardGroup.controls['code'].value){
			this.reApplyGiftCardDiscount('date');
		} else {
			this.reApplyReferral('date');
		}
		this.calcTotalPrice();
	}
	/**
	 * Function is called when provider is changed.
	 */
	public onProviderDetailsChange(): void {
		this.calcTotalPrice();
	}
	/**
	 * Function to recalculate tax for customer address's zipcode.
	 */
	public reCalculateTax(zipcode: any): void{
		if(this.locationLayout != 'zipcode_based'){
			this.calculateTax(zipcode);
		}
	}
	/**
	 * Function to call calculate tax api
	 * @param customerZipcode
	 */
	private calculateTax(customerZipcode : any = ''): void {
		let locationId = this.BKFrm.controls['base_location_id'].value;
		let zipcode = this.BKFrm.controls['zipcode'].value;
		if(!zipcode && customerZipcode){
			zipcode = customerZipcode;
		}
		// let addressGroup = <FormGroup>this.BKFrm.controls['address'];
		// let state = addressGroup.controls['state'].value;
		// let city = addressGroup.controls['city'].value;
		// state = state ? state : '';
		// city = city ? city : '';
		let queryParams: any = {location_id: locationId, postal_code: zipcode, industry_id: this.industryId};
		if(!this.utilServ.isSameObject(this.taxQueryParams, queryParams)){
			this.taxQueryParams = queryParams;
			// if(locationId || zipcode || this.locationLayout == 'no_location') {
				this.apiServ.callApiWithQueryParams('GET', 'Tax', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'tax'));
			// }else{
			// 	this.resetTax();
			// }
		}
	}
	private resetTax(){
		this.priceLocalVar.displaySaleTax = 0;
		this.priceLocalVar.bookingTaxRate = null;
		this.priceLocalVar.bookingTaxType = '';
		this.BKFrm.patchValue({
			booking_tax_rate: +this.priceLocalVar.bookingTaxRate,
			booking_tax_type: this.priceLocalVar.bookingTaxType,
			booking_tax_api: null
		});
		this.calcTotalPrice();
	}
	/**
	 * Function to get the referral discount of customer.
	 */
	private getDailyDiscounts(): void {
		if(this.bkngFormServ.dailyDiscPlanPerm){
			this.isLoadDailyDiscount = false;
			let currentDate = dayjs();
			let startDate = currentDate.format("YYYY-MM-DD");
			let sixMonthDate = dayjs(startDate).add(6, 'month');
			let endDate = sixMonthDate.format("YYYY-MM-DD");
			let locationId = this.BKFrm.controls['base_location_id'].value ? this.BKFrm.controls['base_location_id'].value : 0;
			let serviceId = this.BKFrm.controls['service_category'].value ? this.BKFrm.controls['service_category'].value : null;
			let customerId = this.BKFrm.controls['uid'].value ? this.BKFrm.controls['uid'].value : 0;
			let queryParams: any = {start_date: startDate, end_date: endDate, industry_id: this.industryId, location_id: locationId, service_category: serviceId, customer_id: customerId };
			// Compare the query parameters and then hit the api
			if(!this.utilServ.isSameObject(this.dailyDiscountQueryParam, queryParams)){
				this.dailyDiscountQueryParam = queryParams;
				// Call api to get dailyDisc
				this.apiServ.callApiWithQueryParams('GET', 'DailyDisc', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'dailyDisc'));
			}
		}
	}
	/**
	 * Function to make the array for dates and day for day discount.
	 */
	private makeDayDiscountArray(): void {
		this.dateDiscountsArray = [];
		this.dayDiscountsArray = [];
		let output = this.bkngCmnFun.makeDayDiscountArray(this.dayDiscounts);
		this.dateDiscountsArray = output.dateDiscountsArray;
		this.dayDiscountsArray = output.dayDiscountsArray;
	}
	/**
	 * Get the quote
	 */
	private getQuote(): void {
		this.apiServ.callApiWithPathVariables('GET', 'Quote', [this.quoteId]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'quote'));
	}
	/**
	 * Get the industry id and form id based form settings
	 */
	private getFormBuildSett(): void {
		let queryParams: any = null;
		if(this.bookingType == 'draft'){
			queryParams = {status: 'deleted', draft: true}
		}
		if(!this.quoteId){
			queryParams = {type: 'add'};
		}
		this.apiServ.callApiWithPathQueryVars('GET', 'FormSett', [this.industryId, this.formId], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'formSett'));
	}
	/**
	 * Function to get the available settings for selected form.
	 */
	// TODO: remove this api
	public getFormAvailableSett(): void {
		let status = 'deleted';
		let queryParams: any = {status: status};
		if(this.bookingType == 'draft'){
			queryParams['draft']=true;
		}
		this.apiServ.callApiWithPathQueryVars('GET', 'AvailSett', [this.industryId, this.formId], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'availSett'));
	}
	/**
	 * Add address_details if not exist
	 * @param sections
	 */
	private addAddrDetails(sections: any): void {
		if(!(sections).includes("address_details")){
			let i = (sections).indexOf('customer_details');
			(sections).splice(i+1, 0, 'address_details');
		}
	}
	/**
	 * Form sections get by form builder
	 * According to form set the form sections
	 */
	private formSections(): void {
		let allSectionsArray: any = [];
		// Get the order of form sections
		switch(this.formLayout){
			case 'one_step':
				if(this.formMetaData && this.formMetaData.single_form_sections && (this.formMetaData.single_form_sections).length > 0){
					this.oneStepFormSections = this.formMetaData.single_form_sections;
				}
				// Summary
				if(this.formMetaData && this.formMetaData.side_sections && (this.formMetaData.side_sections).length > 0){
					this.summarySequence = this.formMetaData.side_sections;
				}
				// Add address_details if not exist
				this.addAddrDetails(this.oneStepFormSections);
				allSectionsArray = this.oneStepFormSections;
				this.bkngFormServ.stepWiseCustGrpPos['step_one'] = this.oneStepFormSections;
			break;
			case 'two_step':
				if(this.formMetaData && this.formMetaData.step_one_sections && (this.formMetaData.step_one_sections).length > 0){
					this.twoStepFormSections.step_one = this.formMetaData.step_one_sections;
				}
				if(this.formMetaData && this.formMetaData.step_two_sections && (this.formMetaData.step_two_sections).length > 0){
					this.twoStepFormSections.step_two = this.formMetaData.step_two_sections;
				}
				// Summary
				if(this.formMetaData && this.formMetaData.side_sections && (this.formMetaData.side_sections).length > 0){
					this.summarySequence = this.formMetaData.side_sections;
				}
				// Add address_details if not exist
				if(!(this.twoStepFormSections.step_one).includes("address_details") && !(this.twoStepFormSections.step_two).includes("address_details")){
					if((this.twoStepFormSections.step_one).includes("customer_details")){
						this.addAddrDetails(this.twoStepFormSections.step_one);
					} else {
						this.addAddrDetails(this.twoStepFormSections.step_two);
					}
				}
				allSectionsArray = [...this.twoStepFormSections.step_one, ...this.twoStepFormSections.step_two];
				this.bkngFormServ.stepWiseCustGrpPos['step_one'] = this.twoStepFormSections.step_one;
				this.bkngFormServ.stepWiseCustGrpPos['step_two'] = this.twoStepFormSections.step_two;
			break;
			case 'multi_step':
				// Step one
				if(this.formMetaData && this.formMetaData.multi_stp_form_stp_one_sec && (this.formMetaData.multi_stp_form_stp_one_sec).length > 0){
					this.multiStepFormSections.step_one = this.formMetaData.multi_stp_form_stp_one_sec;
					if(this.multiStepFormSections.step_one && (this.multiStepFormSections.step_one).includes('email') && ((this.multiStepFormSections.step_one).includes('customer_details') || (this.multiStepFormSections.step_one).includes('personal_details')) || (!this.emailSecStatus)){
						const index = (this.multiStepFormSections.step_one).indexOf('email');
						if (index > -1) {
							(this.multiStepFormSections.step_one).splice(index, 1);
						}
					}
				}
				// Step two
				if(this.formMetaData && this.formMetaData.multi_stp_form_stp_two_sec && (this.formMetaData.multi_stp_form_stp_two_sec).length > 0){
					this.multiStepFormSections.step_two = this.formMetaData.multi_stp_form_stp_two_sec;
					if(this.multiStepFormSections.step_two && (this.multiStepFormSections.step_two).includes('email') && ((this.multiStepFormSections.step_two).includes('customer_details') || (this.multiStepFormSections.step_two).includes('personal_details')) || (!this.emailSecStatus)){
						const index = (this.multiStepFormSections.step_two).indexOf('email');
						if (index > -1) {
							(this.multiStepFormSections.step_two).splice(index, 1);
						}
					}
				}
				// Step four
				if(this.formMetaData && this.formMetaData.multi_stp_form_stp_four_lft_sec && (this.formMetaData.multi_stp_form_stp_four_lft_sec).length > 0){
					this.multiStepFormSections.step_four = this.formMetaData.multi_stp_form_stp_four_lft_sec;
				}
				// Summary
				if(this.formMetaData && this.formMetaData.multi_stp_form_stp_four_rigt_sec && (this.formMetaData.multi_stp_form_stp_four_rigt_sec).length > 0){
					this.summarySequence = this.formMetaData.multi_stp_form_stp_four_rigt_sec;
				}
				// Add address_details if not exist
				if(!(this.multiStepFormSections.step_one).includes("address_details") && !(this.multiStepFormSections.step_two).includes("address_details") && !(this.multiStepFormSections.step_four).includes("address_details")){
					if((this.multiStepFormSections.step_one).includes("customer_details")){
						this.addAddrDetails(this.multiStepFormSections.step_one);
					} else if((this.multiStepFormSections.step_two).includes("customer_details")){
						this.addAddrDetails(this.multiStepFormSections.step_two);
					} else {
						this.addAddrDetails(this.multiStepFormSections.step_four);
					}
				}
				allSectionsArray = [...this.multiStepFormSections.step_one, ...this.multiStepFormSections.step_two, ...this.multiStepFormSections.step_three, ...this.multiStepFormSections.step_four];
				this.bkngFormServ.stepWiseCustGrpPos['step_one'] = this.multiStepFormSections.step_one;
				this.bkngFormServ.stepWiseCustGrpPos['step_two'] = this.multiStepFormSections.step_two;
				this.bkngFormServ.stepWiseCustGrpPos['step_four'] = this.multiStepFormSections.step_four;
				// Set the step headings status
				if(this.section){
					let steps: any = ['multi_step_one', 'multi_step_two', 'multi_step_three', 'multi_step_four'];
					let fields: any = ['title','desc','sel_time_slot'];
					this.multiStepsHeadingStatus = {};
					if(steps && steps.length > 0){
						for(let step of steps){
							if(this.section[step]){
								this.multiStepsHeadingStatus[step]= {};
								// eslint-disable-next-line max-depth
								if(fields && fields.length > 0){
									// eslint-disable-next-line max-depth
									for(let field of fields){
										let id = field+'_id';
										// eslint-disable-next-line max-depth
										if(this.section[step][id]){
											// eslint-disable-next-line max-depth
											if(this.multiStepsHeadingStatus[step] && (Object.keys(this.multiStepsHeadingStatus[step])).length == 0){
												this.multiStepsHeadingStatus[step][field] = {}
											}
											this.multiStepsHeadingStatus[step][field] = this.secServ.checkEleStatus(this.pageSett, this.section[step][id]);
										}
									}
								}
							}
						}
					}
				}
			break;
		}
		// Handle form section order
		if(allSectionsArray && allSectionsArray.length > 0){
			for(let i = 0; i < allSectionsArray.length;i++){
				this.sectionOrder[allSectionsArray[i]] = i + 1;
			}
		}
		// Handle summary order
		if(this.summarySequence && this.summarySequence.length > 0){
			for(let i = 0; i < this.summarySequence.length;i++){
				this.sidebarSecOrder[this.summarySequence[i]] = i + 1;
			}
		}
		this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
		this.setMultiStepFormSteps();
		this.addRemoveStepWiseValOnCustFieldsWithDelay();
		this.cDRef.detectChanges();
	}
	/**
	 * Update form
	 */
	private updateForm() : void {
		if(this.settings.form_data && this.settings.form_data.customer_form_layout){
			this.formLayout = this.settings.form_data.customer_form_layout;
			if(!this.bkngFormServ.multiStepPlanPerm && this.formLayout == 'multi_step'){
				this.formLayout = 'one_step';
			}
		}
		// Get the customer details
		this.getCustomerDetailsApi();
		// Update location group
		this.updateLocGroup();
		// Check the service fee taxable default 'no'
		if(this.settings.form_data && this.settings.form_data.is_service_fee_taxable){
			this.priceLocalVar.isServiceFeeTaxable = this.settings.form_data.is_service_fee_taxable;
			this.BKFrm.controls['is_service_fee_taxable'].setValue(this.priceLocalVar.isServiceFeeTaxable);
		} else {
			this.BKFrm.controls['is_service_fee_taxable'].setValue('no');
		}
		// Build form parameters
		this.buildFormParams();
		// Prefilled booking data, based on short form
		if(this.shortFormData && this.industryId == this.shortFormData.industry_id && this.shortFormData.form_id == +this.formId){
			this.prefilledBkngData();
		} else if(this.widgetFormData && (+this.industryId == +this.widgetFormData.industry_id || (!this.widgetFormData.industry_id || this.widgetFormData.industry_id == 'null')) && (+this.widgetFormData.form_id == +this.formId || (!this.widgetFormData.form_id || this.widgetFormData.form_id == 'null'))){
			this.prefilledWidgetFormData(this.widgetFormData);
			this.isAllDataLoaded = true;
		} else if(this.prefilledData){
			if(this.prefilledData.uid && (!this.currentUserId || (this.currentUserId && (+this.currentUserId == +this.prefilledData.uid)))){
				this.prefilledQuoteData();
			} else if(!this.prefilledData.uid && !this.currentUserId) {
				this.prefilledQuoteData();
			} else {
				if(this.prefilledData.uid && this.currentUserId && (+this.currentUserId != +this.prefilledData.uid)){
					this.toastr.error("It seems you are already logged in and quote belongs to another customer, please logout and open the quote again or open in incognito.");
				}
				this.router.navigate(['/'+this.initServ.appDynamicRoutes['booknow']]);
			}
		} else if(this.utilServ.embedStatus && this.embededPrefilledParams &&  (+this.industryId == +this.embededPrefilledParams.industry_id || (!this.embededPrefilledParams.industry_id || this.embededPrefilledParams.industry_id == 'null')) && (+this.embededPrefilledParams.form_id == +this.formId || (!this.embededPrefilledParams.form_id || this.embededPrefilledParams.form_id == 'null'))){
			// Call function to create params from url
			let paramOutput = this.bkngFormServ.createParamObjectFromUrl(this.embededPrefilledParams);
			if(paramOutput[0] && paramOutput[0].length > 0){
				this.embededPrefilledParams['pricing_parameter'] = paramOutput[0];
			}
			if(paramOutput[1] && paramOutput[1].length > 0){
				this.embededPrefilledParams['extras'] = paramOutput[1];
			}
			if(paramOutput[2] && paramOutput[2].length > 0){
				this.embededPrefilledParams['partial_cleaning'] = paramOutput[2];
			}
			this.prefilledWidgetFormData(this.embededPrefilledParams);
			this.isAllDataLoaded = true;
		} else {
			// Set the service category
			this.setServiceCat();
			this.isAllDataLoaded = true;
		}
		// if email is already filled from short form email variation
		let selectedEmail = this.bkngFormServ.selectedEmail;
		if(selectedEmail){
			this.BKFrm.controls['customer'].get('email_id')?.setValue(selectedEmail)
		}

		this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
		this.setMultiStepFormSteps();
		this.addRemoveStepWiseValOnCustFieldsWithDelay();
		// This variable restrict to call change values before form properly loaded.
		this.isReadyToLoad =  true;
		this.calcTotalPrice();
	}
	/**
	 * Build form parameters
	 * Create form control form form 1 & 4
	 */
	private buildFormParams(): void {
		switch(+this.formId){
			case 1:
				// Create control of pricing parameter and exclude parameters
				this.createPricingParamsControls();
				this.createControlArray('partial_cleaning');
			break;
			case 2:
			case 3:
				// TODO: Remove appload data usage, check again (Lakhvir)
				// Get the items and packages `combined_for_same_spot` status
				this.formElemCombStatus = this.bkngFormServ.formElemCombStatus(this.settings);
				break;
			case 4:
				// Create control for area parameter
				this.createPricingAreaControl();
			break;
		}
		// Create extra group in form
		this.createControlArray('extras');
		// Check if pre-qualifier question is there
		this.setSidebarMargin();
	}
	/**
	 * Create a group of array
	 * @param controlName : partial_cleaning/extras
	 */
	private createControlArray(controlName: string): any {
		if(this.BKFrm.controls[controlName]){
			this.BKFrm.removeControl(controlName);
		}
		this.BKFrm.addControl(controlName, this.frmBldr.array([]));
	}
	/**
	 * Set the margin settings for sidebar
	 */
	public setSidebarMargin(): any{
		// Check if pre-qualifier question class height
		let tempSidebarOffset : any;
		let fields: any = document.querySelectorAll('.pre-qualifier-block');
		if(fields && fields.length > 0){
			let height = 0;
			fields.forEach((element:any) => {
				height = height + element.offsetHeight;
			});
			tempSidebarOffset = '-'+height+'px';
		} else{
			tempSidebarOffset = 0;
		}
		this.tempSidebarOffset = tempSidebarOffset;
		this.sidebarOffset = this.tempSidebarOffset;
	}
	/**
	 * Prefilled the some common part of the form params
	 * @param prefilledData : prefilled data
	 */
	private prefilledPartOne(prefilledData: any): any {
		this.BKFrm.patchValue({
			uid: prefilledData.uid,
			zipcode: prefilledData.zipcode,
			base_location_id: prefilledData.base_location_id,
			location_id: prefilledData.location_id,
			location_type: prefilledData.location_type,
			service_category: prefilledData.service_category,
			frequency: prefilledData.frequency,
			base_frequency_id: prefilledData.base_frequency_id,
			is_partial_cleaning: prefilledData.is_partial_cleaning,
			is_marketplace: prefilledData.is_marketplace ? prefilledData.is_marketplace : false,
			address_id: prefilledData.address_id,
			booking_tax_type: prefilledData.booking_tax_type,
			booking_tax_rate: prefilledData.booking_tax_rate,
			created_by: prefilledData.created_by ? prefilledData.created_by : null,
			frequency_discount_on_bookings: prefilledData.frequency_discount_on_bookings,
			frequency_repeat_slug: prefilledData.frequency_repeat_slug,
			ip_address: prefilledData.ip_address,
			occurrence: prefilledData.occurrence,
			service_fee: prefilledData.service_fee,
			notify_customer_for_time: prefilledData.notify_customer_for_time ? prefilledData.notify_customer_for_time : false
		});
		// Prefilled address group
		this.BKFrm.controls['address'].patchValue({
			zipcode: prefilledData.address.zipcode,
			address: prefilledData.address.address,
			city: prefilledData.address.city,
			state: prefilledData.address.state,
			apt: prefilledData.address.apt
		});
	}
	/**
	 * Prefilled the service, frequency and form parameters(pricing parameters/excludes/items/packages/addons/extra/area parameter)
	 */
	private prefilledParams(prefilledData: any): void {
		// Prefilled service category
		this.prefilledServiceCat(prefilledData);
		// Prefilled frequency
		this.prefilledFrequency(prefilledData);
		switch(+this.formId){
			case 1:
				// Prefilled pricing parameter and excludes
				this.prefilledPricingParam(prefilledData);
				this.prefilledExcludes(prefilledData);
			break;
			case 2:
				// Case of form 2, prefilled items, packages and package addons.
				this.prefilledItemsForm2(prefilledData);
			break;
			case 3:
				// Case of form 3, prefilled items and addons.
				this.prefilledItemsForm3(prefilledData);
			break;
			case 4:
				// Case of form 4, prefilled area param.
				this.prefilledPricingAreaParams(prefilledData);
			break
		}
		// Prefilled extras
		this.prefilledExtras(prefilledData);
		this.cDRef.detectChanges();
	}
	/**
	 * Function to reset date and time in case of single form
	 */
	private resetDateTime(msgType : string, frequencyChange: boolean = false){
		if((this.formLayout == 'one_step' || this.formLayout == 'multi_step') && this.BKFrm.controls['arrival_date'].value && (this.serviceProviderComp || this.multiServiceProviderComp) && (this.initServ.appAdmnStngs.merchant_settings?.providers?.scheduling_type != 'manually' || frequencyChange)){
			if(this.serviceProviderComp){
				this.serviceProviderComp.resetDateTime();
			}else{
				if(this.activeStep > 2){
					this.moveToStep('step_three');
					if(!frequencyChange){
						this.toastr.error('Due to change in details, you must re-Select date and time.');
					}
					if(frequencyChange && this.formLayout == 'multi_step'){
						this.toastr.error('Due to change in frequency, you must re-Select date and time.');
					}
				}
				if(this.multiServiceProviderComp){
					this.multiServiceProviderComp.resetDateTime();
				}
			}
		} else {
			this.reApplyCoupon(msgType);
		}
	}
	/**
	 * Get the referral discount
	 */
	private getReferralDiscount(): void {
		this.apiServ.callApiWithPathVariables('GET', 'ReferredUser', [this.BKFrm.controls['uid'].value]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, 'referral'));
	}
	/**
	 * Prefilled booking form in case for short form data
	 */
	private prefilledBkngData(): void {
		this.buildAddressStatus = false;
		this.selectedLocation = this.shortFormData.location_id;
		this.BKFrm.controls['industry_id'].setValue(+this.industryId);
		// Add remove address control
		this.addRemoveAddressControl(this.shortFormData.location_type);
		// Prefilled part one
		this.prefilledPartOne(this.shortFormData);
		// Set the customer email id
		this.BKFrm.controls['customer'].get('email_id')?.setValue(this.shortFormData.customer.email_id);
		// Prefilled pricing params
		this.prefilledParams(this.shortFormData);
		if(this.locationLayout && this.locationLayout != 'zipcode_based' && this.BKFrm.controls['address'].get('zipcode')?.value){
			let zipcode = this.BKFrm.controls['address'].get('zipcode')?.value;
			this.reCalculateTax(zipcode);
		}
		// Reset the value of tips, parking and bonus amount
		this.addRemoveTipParkingBonusControl('tip', '0');
		this.addRemoveTipParkingBonusControl('parking', '0');
		this.addRemoveTipParkingBonusControl('bonus');
		// Price calculation
		this.calcTotalPrice();
		this.getDailyDiscounts();
		this.buildAddressStatus = true;
		if(this.formLayout != 'one_step' && this.formLayout != 'multi_step'){
			this.submitStepOne();
		} else {
			if(this.formLayout == 'multi_step'){
				this.moveToStep('step_three');
				this.setMultiStpFrmFrstStp.emit(true);
			}
		}
	}
	/**
	 * Prefilled the data for quote
	 */
	private prefilledQuoteData(): void{
		this.buildAddressStatus = false;
		// Check if quote is editable by customer or not
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.quote_settings && this.admnStngs.merchant_settings?.quote_settings?.allow_customer_quote_modify && this.admnStngs.merchant_settings?.quote_settings?.allow_customer_quote_modify == 'no'){
			this.isQuoteEditable = false;
		}
		this.selectedLocation = this.prefilledData.location_id;
		this.BKFrm.controls['industry_id'].setValue(+this.industryId);
		// Add remove address control
		this.addRemoveAddressControl(this.prefilledData.location_type);
		// Prefilled part one
		this.prefilledPartOne(this.prefilledData);
		// Set the customer type: existing/new
		if(this.BKFrm.controls['uid'].value){
			this.BKFrm.controls['customer'].get('customer_type')?.setValue('existing customer');
		} else {
			this.BKFrm.controls['customer'].get('customer_type')?.setValue('new customer');
		}
		// Patch customer group value
		this.BKFrm.controls.customer.patchValue({
			address: this.prefilledData.address.address,
			apt: this.prefilledData.address.apt,
			city: this.prefilledData.address.city,
			customer_zipcode: this.prefilledData.customer.zipcode,
			email_id: this.prefilledData.customer.email_id,
			first_name: this.prefilledData.customer.first_name,
			last_name: this.prefilledData.customer.last_name,
			phone_number: this.prefilledData.customer.phone_number,
			profile_pic: this.prefilledData.customer.profile_pic,
			state: this.prefilledData.address.state
		});
		if(this.prefilledData.customer?.send_sms_notification){
			this.BKFrm.controls['customer'].get('send_sms_notification')?.setValue(this.prefilledData.customer?.send_sms_notification);
		}
		if(this.prefilledData.booking_note){
			this.BKFrm.controls['booking_note'].setValue(this.prefilledData.booking_note);
		}
		if(this.prefilledData.private_customer_note){
			this.BKFrm.controls['private_customer_note'].setValue(this.prefilledData.private_customer_note);
		}
		if(this.prefilledData.provider_note){
			this.BKFrm.controls['provider_note'].setValue(this.prefilledData.provider_note);
		}
		if(this.prefilledData.note_from_provider){
			this.BKFrm.controls['note_from_provider'].setValue(this.prefilledData.note_from_provider);
		}
		if(this.prefilledData.special_notes){
			this.BKFrm.controls['special_notes'].setValue(this.prefilledData.special_notes);
		}
		if(this.prefilledData.service_fee){
			this.BKFrm.controls['charge_service_fee'].setValue(true);
		}
		if(this.prefilledData.spot_type){
			this.BKFrm.controls['spot_type'].setValue(this.prefilledData.spot_type);
		}
		if(this.prefilledData.arrival_window){
			this.BKFrm.controls['arrival_window'].setValue(this.prefilledData.arrival_window);
		}
		// Prefilled form parameters
		this.prefilledParams(this.prefilledData);
		// Set tip Patch value
		this.BKFrm.controls['tip'].patchValue({
			amount_type: this.prefilledData.tip.amount_type,
			total_amount: this.prefilledData.tip.total_amount
		});
		// Set the parking total amount
		this.BKFrm.controls['parking'].get('total_amount')?.setValue(this.prefilledData.parking.total_amount)
		// if selected service has disable tips and parking
		if(this.selectedServiceType && !this.selectedServiceType.enable_tip){
			this.addRemoveTipParkingBonusControl('tip');
		}
		if(this.selectedServiceType && !this.selectedServiceType.enable_parking){
			this.addRemoveTipParkingBonusControl('parking');
		}
		// Bonus
		this.BKFrm.controls['bonus'].patchValue({
			status: this.prefilledData.bonus.status,
			reason: this.prefilledData.bonus.reason,
			total_amount: this.prefilledData.bonus.total_amount
		});
		// Patch form values
		this.BKFrm.patchValue({
			key_info : this.prefilledData.key_info,
			key_with_provider : this.prefilledData.key_with_provider,
			key_note : this.prefilledData.key_note,
			alert_charge_now : this.prefilledData.alert_charge_now,
			alert_charge_text : this.prefilledData.alert_charge_text,
			admin_email_reminder : this.prefilledData.admin_email_reminder,
			admin_email_reminder_date : this.prefilledData.admin_email_reminder_date,
			admin_email_reminder_day_before : this.prefilledData.admin_email_reminder_day_before,
			admin_email_reminder_hours_before : this.prefilledData.admin_email_reminder_hours_before,
			admin_email_reminder_message : this.prefilledData.admin_email_reminder_message,
			expedited_amount : this.prefilledData.expedited_amount,
			adjust_price : this.prefilledData.adjust_price,
			price_adjustment_note : this.prefilledData.price_adjustment_note,
			time_adjustment_note : this.prefilledData.time_adjustment_note,
			adjusted_price : this.prefilledData.adjusted_price,
			adjust_time : this.prefilledData.adjust_time,
			adjusted_time : this.prefilledData.adjusted_time,
			exclude_day_discount : this.prefilledData.exclude_day_discount,
			exclude_reschedule_fee : this.prefilledData.exclude_reschedule_fee,
			exclude_service_fee : this.prefilledData.exclude_service_fee,
			exclude_expedited_charge : this.prefilledData.exclude_expedited_charge,
			exclude_cancellation_fee : this.prefilledData.exclude_cancellation_fee,
			exclude_cancellation_fee_after_first_booking : this.prefilledData.exclude_cancellation_fee_after_first_booking,
			exempt_sales_tax : this.prefilledData.exempt_sales_tax,
			exclude_minimum : this.prefilledData.exclude_minimum,
			hide_checklist : this.prefilledData.hide_checklist,
			override_provider_pay : this.prefilledData.override_provider_pay,
			notify_customer_for_time : this.prefilledData.notify_customer_for_time
		});
		// Exclude and exempt
		if(this.prefilledData.exclude_service_fee){
			this.priceLocalVar.excludeServiceFeeValue = true;
		}
		if(this.prefilledData.exclude_expedited_charge){
			this.priceLocalVar.excludeExpeditedAmountValue = true;
		}
		if(this.prefilledData.exempt_sales_tax){
			this.priceLocalVar.exemptSalesTaxValue = true;
		}
		if(this.prefilledData.exclude_day_discount){
			this.priceLocalVar.excludeDayDiscountValue = true;
		}
		// Prefilled coupon
		this.prefilledCoupon();
		// Set the day discount value
		if(this.prefilledData.day_discount && this.prefilledData.day_discount.discount && this.prefilledData.day_discount.discount_type){
			this.BKFrm.controls.day_discount.patchValue({
				discount: this.prefilledData.day_discount.discount,
				discount_type: this.prefilledData.day_discount.discount_type,
				apply_to_all: this.prefilledData.day_discount.apply_to_all
			});
		}
		if(this.locationLayout && this.locationLayout != 'zipcode_based' && this.BKFrm.controls['address'].get('zipcode')?.value){
			let zipcode = this.BKFrm.controls['address'].get('zipcode')?.value;
			this.reCalculateTax(zipcode);
		} else {
			if(!this.BKFrm.controls['zipcode'].value && !this.BKFrm.controls['address'].get('zipcode')?.value){
				this.calculateTax();
			}
		}
		this.calcTotalPrice();
		// this.checkPrefilledServiceProvider();
		this.getDailyDiscounts();
		this.buildAddressStatus = true;
		this.cDRef.detectChanges();
	}
	/**
	 * Function to prefilled the data for widget form.
	 */
	private prefilledWidgetFormData(widgetFormData : any){
		this.buildAddressStatus = false;
		this.referrerSource = null;
		if(widgetFormData && widgetFormData.referrer_source){
			this.referrerSource = widgetFormData.referrer_source;
		}
		// Set user personal details
		if(!this.BKFrm.controls['uid'].value){
			this.BKFrm.controls.customer.patchValue({
				email_id : (widgetFormData.email_id && widgetFormData.email_id != 'null') ? widgetFormData.email_id : '',
				first_name : (widgetFormData.first_name && widgetFormData.first_name != 'null') ? widgetFormData.first_name : '',
				last_name : (widgetFormData.last_name && widgetFormData.last_name != 'null') ? widgetFormData.last_name : '',
				phone_number : (widgetFormData.phone_number && widgetFormData.phone_number != 'null' && !isNaN(+widgetFormData.phone_number)) ? widgetFormData.phone_number : ''
			});
		}
		// Prefilled industry id
		this.BKFrm.controls['industry_id'].setValue(+this.industryId);
		// Prefilled zipcode
		if(widgetFormData.zipcode && widgetFormData.zipcode != 'null'){
			this.BKFrm.controls['zipcode'].setValue(widgetFormData.zipcode);
		}
		// Prefilled location id
		if(widgetFormData.location_id && widgetFormData.location_id != 'null'){
			this.BKFrm.controls['location_id'].setValue(+widgetFormData.location_id);
		}
		// Prefilled service category
		if(widgetFormData.service && widgetFormData.service != 'null'){
			this.BKFrm.controls['service_category'].setValue(+widgetFormData.service)
		}
		this.prefilledServiceCat(widgetFormData, true);
		// Prefilled frequency
		if(widgetFormData.frequency && widgetFormData.frequency != 'null'){
			this.BKFrm.controls['frequency'].setValue(+widgetFormData.frequency)
		}
		this.prefilledFrequency(widgetFormData);
		// To select the location.
		if(widgetFormData.location_id && widgetFormData.location_id != 'null'){
			if(this.settings.locations && (this.settings.locations).length > 0){
				for(let location of this.settings.locations){
					if(+location._id == +widgetFormData.location_id){
						this.selectedLocation = location._id;
						this.setLocation(location, true);
						break;
					}
				}
			}
		}
		switch(+this.formId){
			case 1:
				// Prefilled pricing parameter and excludes
				if(widgetFormData.pricing_parameter && widgetFormData.pricing_parameter != 'null' && (widgetFormData.pricing_parameter).length > 0){
					this.prefilledPricingParam(widgetFormData);
				}
				// Prefilled excludes
				if(widgetFormData.partial_cleaning && (widgetFormData.partial_cleaning).length > 0){
					this.BKFrm.controls['is_partial_cleaning'].setValue(true);
					this.prefilledExcludes(widgetFormData);
				}
			break;
			case 2:
				// Case of form 2, prefilled items, packages and package addons.
				// eslint-disable-next-line no-case-declarations
				let prefilledItemsF2 = widgetFormData.pricing_parameter;
				if(prefilledItemsF2 && prefilledItemsF2 != 'null' && prefilledItemsF2.length > 0){
					widgetFormData['items'] = widgetFormData.pricing_parameter;
					this.prefilledItemsForm2(widgetFormData);
				}
			break;
			case 3:
				// Case of form 3, prefilled items and addons.
				// eslint-disable-next-line no-case-declarations
				let prefilledItemsF3 = widgetFormData.pricing_parameter;
				if(prefilledItemsF3 && prefilledItemsF3 != 'null' && (prefilledItemsF3).length > 0){
					widgetFormData['items'] = widgetFormData.pricing_parameter;
					this.prefilledItemsForm3(widgetFormData);
				}
			break;
			case 4:
				// Case of form 4, prefilled area param.
				if(widgetFormData.pricing_parameter && widgetFormData.pricing_parameter != 'null'){
					widgetFormData['area_parameter'] = widgetFormData.pricing_parameter[0];
					this.prefilledPricingAreaParams(widgetFormData);
				}
			break
		}
		// Prefilled extras
		if(widgetFormData.extras && (widgetFormData.extras).length > 0){
			this.prefilledExtras(widgetFormData);
		}
		/**set the service hourly value **/
		if(widgetFormData.service_hourly_value && widgetFormData.service_hourly_value != 'null'){
			if(this.selectedServiceType && this.selectedServiceType.is_hourly_service && this.selectedServiceType.is_hourly_service == 'yes' && (!this.selectedServiceType.hourly_serv_price_on || (this.selectedServiceType.hourly_serv_price_on &&  this.selectedServiceType.hourly_serv_price_on == 'custom_time'))){
				this.BKFrm.controls['service_hourly_value'].setValue(+widgetFormData.service_hourly_value);
			}
		}
		if(widgetFormData.coupon && widgetFormData.coupon != 'null' && this.utilServ.embedStatus &&  !this.couponCode){
			this.couponCode = widgetFormData.coupon;
		}
		// Reset the value of tips, parking and bonus amount
		this.addRemoveTipParkingBonusControl('tip', '0');
		this.addRemoveTipParkingBonusControl('parking', '0');
		this.addRemoveTipParkingBonusControl('bonus');

		if(widgetFormData && widgetFormData.date && widgetFormData.date != 'null'){
			setTimeout(()=>{
				let prefilledDate = widgetFormData.date;
				if(this.serviceProviderComp){
					this.serviceProviderComp.clickMultiStepDate(prefilledDate);
				}else if(this.multiServiceProviderComp){
					this.multiServiceProviderComp.clickMultiStepDate(prefilledDate);
				}
			}, 1000)
		}
		this.calcTotalPrice();
		this.getDailyDiscounts();
		this.buildAddressStatus = true;
		if(this.formLayout != 'two_step'){
			// this.checkPrefilledServiceProvider();
		}
		if(this.formLayout == 'multi_step'){
			this.moveToStep('step_one');
			this.setMultiStpFrmFrstStp.emit(true);
		}
	}
	/**
	 * Add/remove tip/parking/bonus control
	 * Empty the total amount
	 * @param controlName tip/parking/bonus
	 */
	private addRemoveTipParkingBonusControl(controlName: string, amount: any = '', setAmount: boolean = true): void {
		let formGroup = <FormGroup>this.BKFrm.controls[controlName];
		if(setAmount){
			(formGroup.controls['total_amount']).setValue(amount);
		}
		formGroup.removeControl('each_member_amount');
		formGroup.addControl('each_member_amount',this.frmBldr.array([]));
	}
	/**
	 * Add/remove address control
	 * @param data shortFormData/prefilledData
	 */
	private addRemoveAddressControl(type: string): void {
		(<FormGroup>this.BKFrm.controls['customer']).removeControl('address');
		if(type == 'SA'){
			(<FormGroup>this.BKFrm.controls['customer']).addControl('address', new FormControl('', Validators.required));
		} else{
			(<FormGroup>this.BKFrm.controls['customer']).addControl('address', new FormControl(''));
		}
	}
	/**
	 * Check the form parameter on frequency change
	 */
	private checkParamsOnFreqChange(){
		if(this.formLayout == 'multi_step'){
			this.moveToStep('step_two');
			// this.bkngFormServ.msgPopup('You need to select some parameters again, as you change the frequency.');
			this.bkngFormServ.newMsgPopup('You need to select some parameters again, as you change the frequency.');
		}
		setTimeout(()=>{
			this.calcTotalPrice();
		}, 500);
	}

	/**
	 * On result callback method
	 * @param res API res
	 * @param type res type
	 *
	 * API response handler
	 */
	private async onResultCallback(res:any, type: string, data: any = null) {
		switch(type) {
			case "tax":
				if(this.apiServ.checkAPIRes(res)){
					this.priceLocalVar.bookingTaxRate = this.utilServ.roundToTwo(res.data.tax_rate);
					this.priceLocalVar.bookingTaxType = res.data.tax_type;
					let bookingTaxApi = res.data.tax_method;
					this.BKFrm.controls['booking_tax_rate'].setValue(+this.priceLocalVar.bookingTaxRate);
					this.BKFrm.controls['booking_tax_type'].setValue(this.priceLocalVar.bookingTaxType);
					this.BKFrm.controls['booking_tax_api'].setValue(bookingTaxApi);
					this.calcTotalPrice();
				} else {
					this.resetTax();
				}
			break;
			case "dailyDisc":
				if(this.apiServ.checkAPIRes(res) && res.data){
					if(res.data.apply_to_all){
						this.dayDiscountApplyToAll = res.data.apply_to_all;
					}
					this.dayDiscounts = res.data.discounts;
					this.dayDiscountBookings = res.data.bookings;
				}else{
					this.dayDiscounts = null;
				}
				this.makeDayDiscountArray();
				this.isLoadDailyDiscount = true;
			break;
			case "quote":
				if(this.apiServ.checkAPIRes(res)){
					this.prefilledData = res.data;
					this.bkngCustSecServ.prefilledData = res.data;
					this.bkngCustSecServ.isRescFirstLoad = true;
				}
				this.getFormBuildSett();
				// Get the form available settings
				// this.getFormAvailableSett();
			break;
			case "formSett":
				if(this.apiServ.checkAPIRes(res)){
					this.settings = res.data;
					// Active inactive form params
					if(this.settings?.active_inactive){
						this.availableSett = this.settings.active_inactive;
					}
					// // Todo: harleen remove this api:: merchant/form-settings/P1/P2/settings
					// this.addBkngServ.singleFormSett = this.settings?.form_data;
					// if(this.addBkngServ.singleFormSett){
					// 	console.log('booking form migration callback ');
					// 	this.secServ.formSettMigCallback(this.secId, this.addBkngServ.singleFormSett);
					// }
					// TODO: Remove appload data usage, check again (Lakhvir)
					this.bkngFormServ.elementName = this.bkngFormServ.formElementName(this.settings);
					switch(+this.formId){
						case(1):
							// TODO: Remove appload data usage, check again (Lakhvir)
							// set global variable for pricing parameter
							this.settingsObj.pricing_param = this.bkngCmnFun.createGlobalPricingParam(this.formId, this.settings);
							// set global variable for excludes
							this.settingsObj.excludes = this.bkngFormServ.getSettingParamsObj('excludes', this.settings);
							break;
						case(2):
							// set global variable for packages
							this.settingsObj.package = this.bkngFormServ.getSettingParamsObj('package', this.settings);
							// set global variable for package addons
							this.settingsObj.package_addons = this.bkngFormServ.getSettingParamsObj('package_addons', this.settings);
							break;
						case(3):
							// set global variable for addons
							this.settingsObj.addons = this.bkngFormServ.getSettingParamsObj('addons', this.settings);
							break;
						case(4):
							// TODO: Remove appload data usage, check again (Lakhvir)
							// set global variable for pricing parameter
							this.settingsObj.area_param = this.bkngCmnFun.createGlobalPricingParam(this.formId, this.settings);
							break;
					}
					// set global variable for addons
					this.settingsObj.extras = this.bkngFormServ.getSettingParamsObj('extras', this.settings);
					if(this.settings){
						await this.updateForm();
					}
				}
				// Form section by theme builder
				this.formSections();
			break;
			case "availSett":
				if(this.apiServ.checkAPIRes(res)){
					this.availableSett = res.data;
				}
			break;
			case "referral":
				if(this.apiServ.checkAPIRes(res) && res.data){
					let referral: any = res.data;
					if(referral && !referral.is_claimed){
						this.BKFrm.controls['referral_discount'].setValue(referral.referral_amount);
						this.BKFrm.controls['referral_user_id'].setValue(referral.customer_id);
					}
				}
			break;
			case "multi-step-status":
				this.checkVisibilityFirstStep(res, data);
			break
		}
		this.cDRef.detectChanges();
	}

	// Sub components functions

	/**
	 * Service and frequency change reset the form params
	 * @param multiStepFlag : multi step flag
	 * @param flag : flag for getDailyDiscounts
	 */
	private serviceFreqChange(multiStepFlag: boolean = false, flag: boolean = false){
		let changeCount: number = 0;
		switch(+this.formId){
			case 1:
				changeCount = changeCount + this.resetPricingParams();
				changeCount = changeCount + this.resetExcludesAndExtras('partial_cleaning');
			break;
			case 2:
				// eslint-disable-next-line no-case-declarations
				let typeParam : string = 'not_reset_with_zero';
				if(this.settings && this.settings.items && (this.settings.items).length > 0){
					typeParam = 'reset_with_zero';
				}
				changeCount = changeCount + this.bkngFormServ.resetItemsForm2(this.BKFrm, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, typeParam, this.bookingType, this.prefilledFormParams.items);
				if(this.itemsWithPckgsComp){
					// Reset the variables
					this.itemsWithPckgsComp.selectedItem = null;
					let inputElms = document.querySelectorAll('input[name="item"]');
					if(inputElms && inputElms.length > 0){
						inputElms.forEach((element:any) => element.checked = false);
					}
					// TO refresh the component, to solve the reloading issue.
					this.itemsWithPckgsComp.refresh();
				}
			break;
			case 3:
				changeCount = changeCount + this.bkngFormServ.resetItemsForm3(this.BKFrm, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.items);
				if(this.itemsWithAddonsComp){
					// Reset the variables
					this.itemsWithAddonsComp.selectedItem = null;
					this.itemsWithAddonsComp.selectedAddonIds = [];
					let inputElms = document.querySelectorAll('input[name="item"]');
					if(inputElms && inputElms.length > 0){
						inputElms.forEach((element:any) => element.checked = false);
					}
				}
			break;
			case 4:
				changeCount = changeCount + this.bkngFormServ.resetPricingAreaParams(this.BKFrm, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.area_param);
				// We comment this code because it call the refresh method twice, that generate an issue of blank area param on service/freq change, if param not available for both services. so to comment this code it solved the issue (issue on live account: wabycleansllc)
				// if(changeCount > 0 && this.pricingParameterWithAreaComp){
				// 	this.pricingParameterWithAreaComp.refresh();
				// }
				break;
		}
		changeCount = changeCount + this.resetExcludesAndExtras('extras');
		if(changeCount > 0 && multiStepFlag){
			this.checkParamsOnFreqChange();
		}
		// Get daily discount according to customer
		if(flag){
			this.getDailyDiscounts();
		}
		this.calcTotalPrice();
	}
	/**
	 * Reset form control values
	 * @param type
	 */
	public resetFormControl(type: string, flag: any = true): void {
		switch(type) {
			case 'location':
				this.resetAllFormParamsOnLocChange();
				// this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
				this.reApplyCoupon('location');
			break;
			case 'service':
				this.serviceFreqChange(false,true);
				this.resetExcludesAndExtras('extras');
				this.reApplyCoupon('service');
				this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
				this.setMultiStepFormSteps();
				this.addRemoveStepWiseValOnCustFieldsWithDelay();
			break;
			case 'hourly-service':
			case 'items-params':
				this.resetDateTime('amount');
				this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
				this.setMultiStepFormSteps();
				this.addRemoveStepWiseValOnCustFieldsWithDelay();
				this.calcTotalPrice();
			break;
			case 'pricing-param':
				this.resetDateTime('amount');
				this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
				this.setMultiStepFormSteps();
				this.resetExcludesAndExtras('extras');
				this.resetExcludesAndExtras('partial_cleaning');
				this.addRemoveStepWiseValOnCustFieldsWithDelay();
				this.calcTotalPrice();
			break
			case 'exclude':
			case 'extras':
				this.resetDateTime('amount');
				this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
				this.setMultiStepFormSteps();
				this.addRemoveStepWiseValOnCustFieldsWithDelay();
				this.calcTotalPrice();
			break;
			case 'items':
			case 'package':
				this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
				this.setMultiStepFormSteps();
				this.resetExcludesAndExtras('extras');
				this.addRemoveStepWiseValOnCustFieldsWithDelay();
				this.calcTotalPrice();
				this.isNoItemSelected = false;
				this.isNoPackageSelected = false;
			break;
			case 'area-params':
				this.resetDateTime('amount');
				this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
				this.setMultiStepFormSteps();
				this.resetExcludesAndExtras('extras');
				this.addRemoveStepWiseValOnCustFieldsWithDelay();
				this.calcTotalPrice();
			break;
			case 'customer':
			case 'address':
				if(this.locationLayout != 'zipcode_based'){
					this.calculateTax();
				}
			break;
			case 'custom-fields':
				if(flag) {
					this.resetDateTime('amount');
				}
				this.calcTotalPrice();
			break;
		}
		if(type != 'location'){
			this.checkEmailVali();
		}
	}
	// Location and zipcode section functions
	/**
	 * Update loaction group controls values
	 */
	private updateLocGroup(): void {
		// Set the location id 0 in case of no_location
		// Zipcode based add validation
		if(this.settings.form_data && this.settings.form_data.location_type){
			this.locationLayout = this.settings.form_data.location_type;
			this.BKFrm.controls['zipcode'].markAsUntouched();
			this.BKFrm.controls['zipcode'].clearValidators();
			this.BKFrm.controls['location_id'].clearValidators();
			if(this.locationLayout == 'no_location'){
				this.BKFrm.controls['location_type'].setValue('SA');
				this.BKFrm.controls['location_id'].setValue(0);
				this.BKFrm.controls['base_location_id'].setValue(0);
			} else if (this.locationLayout == 'zipcode_based'){
				this.BKFrm.controls['zipcode'].setValidators(Validators.required);
				this.BKFrm.controls['location_id'].setValidators(Validators.required);
			} else{
				this.BKFrm.controls['location_id'].setValidators(Validators.required);
			}
			// calculate tax
			this.calculateTax();
			this.BKFrm.controls['zipcode'].updateValueAndValidity();
			this.BKFrm.controls['location_id'].updateValueAndValidity();
		}
		// Set the location type
		if(this.locationLayout != 'no_location'){
			if(this.settings.form_data && this.settings.form_data.preferred_service_location && this.settings.form_data.preferred_service_location == 'merchant_location'){
				this.BKFrm.controls['location_type'].setValue('ML');
			} else{
				this.BKFrm.controls['location_type'].setValue('SA');
			}
		}
	}
	/**
	 * Set the location based service fee
	 * @param loc
	 */
	private setLocBasedServiceFee(loc: any): void {
		if(loc.charge_service_fee && loc.charge_service_fee == 'yes'){
			let serviceFee = this.utilServ.roundToTwo(+loc.customer_amount);
			this.BKFrm.controls['service_fee'].setValue(serviceFee);
			this.BKFrm.controls['charge_service_fee'].setValue(true);
		} else{
			this.BKFrm.controls['service_fee'].setValue(null);
			this.BKFrm.controls['charge_service_fee'].setValue(false);
		}
	}
	/**
	 * Set the location
	 * If location layout is `name_based`
	 * @param location : Selected location
	 */
	public setLocation(location: any, isWidgets: boolean = false): void {
		if(location){
			this.loader.show(this.loaderIds.location);
			this.BKFrm.controls.address.patchValue({
				zipcode: null,
				address: null,
				city: null,
				state: null,
				apt: null
			});
			this.selectedLocation = location._id;
			if(!isWidgets){
				this.BKFrm.controls['zipcode'].setValue(null);
			}
			this.BKFrm.patchValue({
				address_id: null,
				base_location_id: location.location_id,
				location_id: location._id,
				location_type: location.location.location_type
			});
			// Add remove address control
			this.addRemoveAddressControl(this.BKFrm.controls['location_type'].value);
			// Set the address in case of `ML`
			if(this.BKFrm.controls['location_type'].value == 'ML'){
				this.BKFrm.controls.address.patchValue({
					address: location.location.merchant_location_address,
					city: location.location.city ? location.location.city : '',
					state: location.location.state ? location.location.state : '',
					zipcode: location.location.zipcode,
					apt: location.location.apt
				});
				this.BKFrm.controls['address_id'].setValue(location._id);
				this.BKFrm.controls['zipcode'].setValue(location.location.zipcode);
			}
			this.BKFrm.controls['credit_card'].get('location_account_type')?.setValue(location.account_type);
			// Set service based on location
			this.setLocBasedServiceFee(location);
		}
		this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
		this.setMultiStepFormSteps();
		this.resetFormControl('location'); // Reset location based parameters
		this.addRemoveStepWiseValOnCustFieldsWithDelay();
		this.refreshLocComp = isWidgets; // True in case of widgets, other wise false.(Refresh the zipcode component)
		this.loader.hide(this.loaderIds.location);
	}
	/**
	 * Check the zipcode availability
	 * @param selectedLoc : selected location
	 * SA location type
	 */
	public checkZipcodeAval(selectedLoc: any): void {
		let oldLocation = this.selectedLocation;
		if(selectedLoc){
			this.selectedLocation = selectedLoc._id;
			this.BKFrm.patchValue({ base_location_id: selectedLoc.location_id, location_id: selectedLoc._id });
			this.BKFrm.controls['credit_card'].get('location_account_type')?.setValue(selectedLoc.account_type);
			// Set service based on location
			this.setLocBasedServiceFee(selectedLoc);
		} else {
			this.selectedLocation = null;
			this.BKFrm.patchValue({ base_location_id: null, location_id: null });
			this.BKFrm.controls['credit_card'].get('location_account_type')?.setValue(null);
		}
		this.zipcodeValue = this.BKFrm?.value?.zipcode;
		if(oldLocation != this.selectedLocation){
			this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
			this.setMultiStepFormSteps();
			this.resetFormControl('location'); // Reset location based parameters
			this.addRemoveStepWiseValOnCustFieldsWithDelay();
		}
		// this.resetFormControl('location'); // Reset location based parameters
	}
	/**
	 * Check the merchant location zipcode availability
	 * @param selectedLoc : selected location
	 * ML location type
	 */
	public checkMerchantZipcodeAval(selectedLoc: any): void {
		let oldLocation = this.selectedLocation;
		this.selectedLocation = null;
		// ML case
		if(selectedLoc && selectedLoc.location_type == 'ML'){
			this.selectedLocation = this.BKFrm.controls['location_id'].value;
			this.BKFrm.controls['credit_card'].get('location_account_type')?.setValue(selectedLoc.account_type);
			this.BKFrm.patchValue({ service_fee: null, charge_service_fee: false });
		}
		if(oldLocation != this.selectedLocation){
			this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
			this.setMultiStepFormSteps();
			this.resetFormControl('location'); // Reset location based parameters
			this.addRemoveStepWiseValOnCustFieldsWithDelay();
		}
		// this.resetFormControl('location'); // Reset location based parameters
	}
	/**
	 * Reset all form parameters on location change
	 */
	private resetAllFormParamsOnLocChange(): void {
		let typeParam : string = 'not_reset_with_zero';
		this.checkSelectedService();
		this.checkSelectedFrequency();
		switch(+this.formId){
			case 1:
				this.resetPricingParams();
				this.resetExcludesAndExtras('partial_cleaning');
			break;
			case 2:
				if(this.utilServ.checkArrLength(this.settings.items)){
					typeParam = 'reset_with_zero';
				}
				this.bkngFormServ.resetItemsForm2(this.BKFrm, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, typeParam, this.bookingType, this.prefilledFormParams.items);
			break;
			case 3:
				this.bkngFormServ.resetItemsForm3(this.BKFrm, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.items);
			break;
			case 4:
				this.bkngFormServ.resetPricingAreaParams(this.BKFrm, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.area_param);
			break;
		}
		this.resetExcludesAndExtras('extras');
		// Function to get the day discounts.
		this.getDailyDiscounts();
		this.calcTotalPrice();
	}

	// Service category functions
	/**
	 * Prefilled service category
	 * @param prefilledData
	 */
	private prefilledServiceCat(prefilledData: any, isWidgets: boolean = false): void {
		let validService: boolean = false;
		let services: any = [];
		let selectedService: any;
		if(this.settings.service_category && (this.settings.service_category).length > 0){
			for(let service of this.settings.service_category){
				if(this.bkngFormServ.serviceVisible(service, this.settings, this.selectedLocation,this.bookingType, this.prefilledData)){
					if(service.id == (this.BKFrm.controls['service_category'].value)){
						validService = true;
						selectedService = service;
					}
					if(!validService){
						services.push(service);
					}
				}
			}
		}
		// Service is archive/delete then pick the first service
		if(!validService && services && services.length > 0){
			selectedService = services[0];
		}
		if(!isWidgets){
			this.selectedServiceType = selectedService;
			// Service name
			this.serviceName = this.utilServ.getFormParamName(this.selectedServiceType);
			// Patch value
			this.BKFrm.patchValue({
				service_category : this.selectedServiceType && this.selectedServiceType.id,
				can_decline_job : prefilledData.can_decline_job,
				is_service_hourly : prefilledData.is_service_hourly,
				service_hourly_value : prefilledData.service_hourly_value ? prefilledData.service_hourly_value : 0,
				service_price_overridden : prefilledData.service_price_overridden,
				service_price_overridden_value : prefilledData.service_price_overridden_value,
				override_service_total : prefilledData.override_service_total,
				overridden_service_total : prefilledData.overridden_service_total,
				exclude_extra_time : prefilledData.exclude_extra_time,
				exempt_extras_price : prefilledData.exempt_extras_price
			});
			// Adjust hourly time
			if(prefilledData.service_hourly_value && prefilledData.adjust_time_hourly){
				this.BKFrm.controls['adjust_time_hourly'].setValue(prefilledData.adjust_time_hourly);
			}
		} else {
			this.serviceCatChange(selectedService, false);
		}
	}
	/**
	 * Check the selected service category visibility
	 */
	private checkSelectedService(): void {
		if(this.selectedServiceType){
			if(this.selectedServiceType.based_on_location != 'no'){
				if(!this.bkngFormServ.serviceVisible(this.selectedServiceType, this.settings, this.selectedLocation,this.bookingType, this.prefilledData)){
					this.setServiceCat();
				}
			}
		}else{
			this.setServiceCat();
		}
	}
	/**
	 * Set the service category
	 */
	private setServiceCat(): void {
		this.BKFrm.controls['service_category'].setValue(null);
		this.selectedServiceType = null;
		if(this.settings.service_category && (this.settings.service_category).length > 0){
			for(let service of this.settings.service_category){
				if(this.bkngFormServ.serviceVisible(service, this.settings, this.selectedLocation,this.bookingType, this.prefilledData)){
					this.serviceCatChange(service, false);
					break;
				}
			}
		}
	}
	/**
	 * Service category change
	 * Redefined the other parameters according to selected service.
	 * @param service: service
	 * @param flag: true/false
	 */
	public serviceCatChange(service: any, flag: any): void {
		this.selectedServiceType = service;
		if(this.selectedServiceType){
			// Patch the service form value
			this.BKFrm.patchValue({
				service_category: service.id,
				can_decline_job: service.can_decline_job,
				is_service_hourly: service.is_hourly_service,
				adjust_time_hourly: false,
				service_price_overridden: false,
				service_price_overridden_value: null
			});
			// Set the minimum time value for service custom time(hourly service)
			if(this.selectedServiceType.enable_minimum_time && this.selectedServiceType.is_hourly_service == 'yes' && (!this.selectedServiceType.hourly_serv_price_on || (this.selectedServiceType.hourly_serv_price_on &&  this.selectedServiceType.hourly_serv_price_on == 'custom_time'))){
				let minimumTime = this.selectedServiceType.minimum_time ? (+this.selectedServiceType.minimum_time) : 0;
				this.BKFrm.controls['service_hourly_value'].setValue(+minimumTime);
			} else {
				this.BKFrm.controls['service_hourly_value'].setValue(0);
			}
			// Flag true reset the date time
			if(flag){
				this.resetDateTime('service', true);
				this.BKFrm.controls['notify_customer_for_time'].setValue(false);
			}
			// Override provider pay
			if(this.selectedServiceType.override_provider_pay){
				this.BKFrm.controls['override_provider_pay'].setValue(true);
			} else{
				this.BKFrm.controls['override_provider_pay'].setValue(false);
			}
			// Exclude service fee
			if(this.selectedServiceType.charge_service_fee == 'no'){
				this.priceLocalVar.excludeServiceFeeValue = true;
				this.BKFrm.controls['exclude_service_fee'].setValue(true);
			} else{
				this.priceLocalVar.excludeServiceFeeValue = false;
				this.BKFrm.controls['exclude_service_fee'].setValue(false);
			}
			// Save to boooking(tip/parking)
			this.saveToBooking('tip');
			this.saveToBooking('parking');
			// Reset the value of tips, parking and bonus amount on service change
			this.addRemoveTipParkingBonusControl('tip', '0');
			this.addRemoveTipParkingBonusControl('parking', '0');
			this.addRemoveTipParkingBonusControl('bonus');
			// Frequency is empty, set the frequency. otherwise check selected frequency
			if(!this.selectedFrequency){
				this.setFrequency();
			} else {
				this.checkSelectedFrequency();
			}
			// If service category have own expedited amount
			if(this.selectedServiceType.expedited_charge && this.selectedServiceType.enable_expedited_charge){
				let serviceExpeditedAmount = this.utilServ.roundToTwo(this.selectedServiceType.expedited_charge);
				this.BKFrm.controls['expedited_amount'].setValue(serviceExpeditedAmount);
			} else{
				this.BKFrm.controls['expedited_amount'].setValue(null);
			}
		}
		this.resetFormControl('service'); // Reset service based form parameters
	}
	/**
	 * Save to booking depend on service tip and parking
	 * @param controlName
	 */
	private saveToBooking(controlName: string): void {
		let enableStatus: any= this.selectedServiceType['enable_'+controlName];
		let saveToBookingControl: any = (<FormGroup>this.BKFrm.controls[controlName]).controls['save_to_booking']
		if(enableStatus){
			saveToBookingControl.setValue(this.selectedServiceType[controlName].save_to_booking);
		} else{
			saveToBookingControl.setValue(null);
		}
	}

	// Frequency functions
	/**
	 * Prefilled frequency
	 */
	private prefilledFrequency(prefilledData: any): void {
		let frequencyId = this.BKFrm.controls['frequency'].value;
		let validFreq: boolean = false;
		if(this.settings.frequencies && (this.settings.frequencies).length > 0){
			for(let frequency of this.settings.frequencies){
				if(this.bkngFormServ.frequencyVisible(frequency,this.settings, this.selectedLocation, this.selectedServiceType, this.bookingType, prefilledData)){
					if(frequency.form_frequency_data.id == frequencyId){
						validFreq = true;
						this.frequencyChange(frequency, false);
					}
				}
			}
		}
		// Not match frequency then set the default frequency
		if(!validFreq){
			this.setFrequency();
		}
	}
	/**
	 * Set the frequency
	 * Based on selected service and location
	 */
	private setFrequency(): void {
		// Select frequency dependent on service_category
		let isDefaultFreq = false;
		let validFrequencies: any = [];
		this.selectedFrequency = null;
		this.BKFrm.controls['frequency'].setValue(null);
		// Loop to check if default frequency in available for service
		if(this.settings.frequencies && (this.settings.frequencies).length > 0){
			for(let frequency of this.settings.frequencies){
				if(this.bkngFormServ.frequencyVisible(frequency,this.settings, this.selectedLocation, this.selectedServiceType, this.bookingType, this.prefilledData)){
					if(frequency.form_frequency_data.default){
						this.frequencyChange(frequency,false);
						isDefaultFreq = true;
						break;
					}
					if(!isDefaultFreq){
						validFrequencies.push(frequency);
					}
				}
			}
		}
		// If default is not available, then selected the first frequency in the queue
		if(!isDefaultFreq && validFrequencies && validFrequencies.length > 0){
			this.frequencyChange(validFrequencies[0],false);
		}
	}
	/**
	 * Check selected frequency and set again(if frequecy visibility is false)
	 */
	private checkSelectedFrequency(): void {
		if(this.selectedFrequency && this.selectedFrequency.id){
			if(!(this.bkngFormServ.frequencyVisible(this.selectedFrequency,this.settings, this.selectedLocation, this.selectedServiceType, this.bookingType, this.prefilledData))){
				this.setFrequency();
			}
		}else{
			this.setFrequency();
		}
	}
	/**
	 * Frequency change
	 * @param frequency : form frequency
	 * @param flag : change flag
	 * @param multiStepFlag : multi step flag
	 */
	public frequencyChange(frequency: any, flag: boolean = false, multiStepFlag : boolean = false): void {
		this.loader.show(this.loaderIds.frequency);
		this.selectedFrequency = frequency;
		// Frequency name
		this.frequencyName = this.utilServ.getFrequencyName(this.selectedFrequency);
		// Set the frequency form value
		this.BKFrm.patchValue({
			frequency: frequency.form_frequency_data.id,
			base_frequency_id: frequency.id,
			charge_onetime_cancellation: frequency.form_frequency_data.charge_onetime_cancellation,
			occurrence: frequency.occurence_time,
			frequency_repeat_slug: frequency.repeat_every,
			frequency_discount_on_bookings: frequency.form_frequency_data.frequency_discount_on_bookings
		});
		if(flag){
			// reset date time
			this.resetDateTime('frequency', true);
		}
		this.serviceFreqChange(multiStepFlag);
		this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
		this.setMultiStepFormSteps();
		if(this.BKFrm?.value?.coupon?.code){
			this.reApplyCoupon('frequency');
		}
		this.addRemoveStepWiseValOnCustFieldsWithDelay();
		// Used in price calculation for frequency section.
		// To change the frequency discount on frequency change.
		// Deduct the frequency discount from service price.
		this.calcTotalPrice();
		this.checkEmailVali();
		this.loader.hide(this.loaderIds.frequency);
		this.cDRef.detectChanges();
	}

	// Pricing parameter functions
	/**
	 * Prefilled pricing parameter
	 * @param prefilledData
	 */
	private prefilledPricingParam(prefilledData: any): void {
		let obj: any={};
		let backupPricingParams: any = this.BKFrm.controls['pricing_parameter'];
		if(this.BKFrm.controls['pricing_parameter']){
			this.BKFrm.removeControl('pricing_parameter');
		}
		this.BKFrm.addControl('pricing_parameter', this.frmBldr.array([]));
		let idsArray : any = [];
		if(prefilledData && prefilledData.pricing_parameter && (prefilledData.pricing_parameter).length > 0){
			let pricingParamFormArray: any = <FormArray>this.BKFrm.controls['pricing_parameter'];
			for(let pricingParam of prefilledData.pricing_parameter){
				if(this.settingsObj?.pricing_param[pricingParam?.id]){
					obj[pricingParam.quantity] = pricingParam;
					let control: any = {
						id: pricingParam.id,
						name: [pricingParam.name ? pricingParam.name : (this.settingsObj.pricing_param[pricingParam.id] && this.utilServ.getParamCat(this.settingsObj.pricing_param[pricingParam.id]))],
						quantity: pricingParam.quantity,
						recurring: null
					};
					idsArray.push(pricingParam.id);
					pricingParamFormArray.push(this.frmBldr.group(control));
				}
			}
		}

		// This code is here because we need to cover a case, when user book a booking with none option for some params but after that disable the none param then need to prefilled the other params withdefault value.
		if(!this.settings?.form_data?.none_pricing_param || this.settings?.form_data?.none_pricing_param == 'no'){
			let pricingParamArray: any = <FormArray>this.BKFrm.controls['pricing_parameter'];
			for(let existParam of backupPricingParams.value){
				if(!idsArray.includes(existParam.id)){
					if(this.bkngFormServ.pricingParamsValueVisible(existParam, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.pricing_param)){
						obj[existParam.quantity] = existParam;
						pricingParamArray.push(this.frmBldr.group(existParam));
					}
				}
			}
		}
		this.prefilledFormParams.pricing_param = obj;
	}
	/**
	 * Create pricing parameter controls
	 */
	public createPricingParamsControls(): void {
		if(this.BKFrm.controls['pricing_parameter']){
			this.BKFrm.removeControl('pricing_parameter');
		}
		this.BKFrm.addControl('pricing_parameter', this.frmBldr.array([]));
		if(this.settings && this.settings.pricing_parameters && (this.settings.pricing_parameters).length > 0){
			let pricingParamFormArray = <FormArray>this.BKFrm.controls.pricing_parameter;
			for(let pricingParam of this.settings.pricing_parameters){
				let control: any = {
					id: pricingParam.id,
					name: pricingParam.name,
					quantity: null,
					recurring: null
				};
				if(pricingParam.value && (pricingParam.value).length > 0){
					let temp = 0;
					let selectedParam = 0;
					for(let section of pricingParam.value){
						// eslint-disable-next-line max-depth
						if(this.bkngFormServ.pricingParamsValueVisible(section, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.pricing_param)){
							// eslint-disable-next-line max-depth
							if(temp == 0){
								selectedParam = section.id;
								temp ++;
							}
							// eslint-disable-next-line max-depth
							if(section.default){
								selectedParam = section.id;
							}
						}
					}
					control['quantity']= selectedParam;
					pricingParamFormArray.push(this.frmBldr.group(control));
				}
			}
		}
		this.calcTotalPrice();
	}
	/**
	 * Get the selected pricing parameter valid value quantity
	 * @param selectedParam : selected pricing parameter
	 * @returns quantity
	 */
	private getPricingParamValue(selectedParam: any): any{
		let sectionData: any = 0;
		if(selectedParam.value && (selectedParam.value).length > 0){
			for(let section of selectedParam.value){
				sectionData = this.bkngFormServ.getPricingParamQuantity(section, 0, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.pricing_param);
				if(sectionData && sectionData.quantity != 0){
					return +sectionData.quantity;
				}
			}
		}
		return null;
	}
	/**
	 * Reset the pricing parameter
	 * @returns change count
	 */
	private resetPricingParams(): any {
		let changeCount: number = 0;
		let flag: number = 0 ;
		// Check if all the param have empty value or not
		if(this.BKFrm.controls['pricing_parameter'].value){
			(this.BKFrm.controls['pricing_parameter'].value).forEach((pricingParam: { quantity: any; }) => {
				if(pricingParam.quantity){
					flag = flag + 1;
				}
			});
		}
		if(flag == 0){
			// If all param have empty value the recreate array
			this.createPricingParamsControls();
			changeCount = changeCount + 1;
		} else {
			// If all param have not empty value then go further
			if(this.settings && this.settings.pricing_parameters && (this.settings.pricing_parameters).length > 0){
				for(let pricingParam of this.settings.pricing_parameters){
					(this.BKFrm.controls['pricing_parameter'].value).forEach((selectedParam: any, i: any) => {
						if(pricingParam.value && (pricingParam.value).length > 0){
							for(let section of pricingParam.value){
								let quantityControl: any = (<FormGroup>(<FormArray>this.BKFrm.controls['pricing_parameter']).controls[i]).controls['quantity'];
								if(selectedParam.quantity){
									if(selectedParam.quantity == section.id){
										// eslint-disable-next-line max-depth
										if(!(this.bkngFormServ.pricingParamsValueVisible(section, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.pricing_param))){
											let quantity: any = this.getPricingParamValue(pricingParam);
											quantityControl.setValue(quantity);
											changeCount = changeCount + 1;
											break;
										}
									}
								} else {
									// TODO not generate this case
									if(selectedParam.name == pricingParam.name){
										let sectionData = this.bkngFormServ.getPricingParamQuantity(section, changeCount, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.pricing_param);
										// eslint-disable-next-line max-depth
										if(sectionData.quantity != 0){
											// Set control
											quantityControl.setValue(+sectionData.quantity);
											break;
										}
										changeCount = sectionData.changeCount
									}
								}
							}
						}
					});
				}
			}
		}
		this.calcTotalPrice();
		return changeCount;
	}

	// Exclude(Partial Cleaning) functions
	/**
	 * Prefilled excludes
	 * @param prefilledData
	 */
	private prefilledExcludes(prefilledData: any): void {
		let excludesFormArray: any = <FormArray>this.BKFrm.controls.partial_cleaning;
		let prefilledExcludes: any = {};
		if(prefilledData.partial_cleaning && (prefilledData.partial_cleaning).length > 0){
			for(let exclude of prefilledData.partial_cleaning){
				if(this.settingsObj?.excludes[exclude?.id]){
					prefilledExcludes[exclude.id] = exclude;
					let control = this.frmBldr.group({
						id: [exclude.id],
						name: [exclude.name ? exclude.name : (this.settingsObj.excludes[exclude.id] && this.utilServ.getFormParamName(this.settingsObj.excludes[exclude.id]))],
						quantity: this.paramQuantity(exclude, this.settingsObj.excludes),
						is_multiple: [this.settingsObj.excludes[exclude.id] && (this.settingsObj.excludes[exclude.id]).enable_quantity_based],
						apply_on_bookings: [exclude.apply_on_bookings ? exclude.apply_on_bookings : (this.settingsObj.excludes[exclude.id] && (this.settingsObj.excludes[exclude.id]).apply_on_bookings)],
						recurring: []
					});
					excludesFormArray.push(control);
				}
			}
		}
		this.prefilledFormParams.excludes = prefilledExcludes;
	}
	/**
	 * Param quantity
	 * @param param
	 * @param paramSett
	 * @returns
	 */
	private paramQuantity(param: any, paramSett: any){
		if((param.quantity) && param.quantity <= (paramSett[param?.id])?.quantity_based){
			return param.quantity;
		}
		return 1;
	}
	/**
	 * Reset the excludes / extras
	 * @param controlName partial_cleaning / extras
	 * @returns
	 */
	private resetExcludesAndExtras(controlName: string): any {
		let output: any = null;
		if(controlName == 'partial_cleaning'){
			output = this.bkngFormServ.resetExcludes(this.BKFrm, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.excludes);
		} else {
			output = this.bkngFormServ.resetExtras(this.BKFrm, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.bookingType, this.prefilledFormParams.extras);
		}
		if(output){
			if(!output[0]){
				this.createControlArray(controlName);
			}
			this.calcTotalPrice();
			return +output[1];
		}
	}

	// Extras functions
	/**
	 * Prefilled extras
	 * @param prefilledData: prefilled data
	 */
	private prefilledExtras(prefilledData: any): void {
		let extrasFormArray = <FormArray>this.BKFrm.controls['extras'];
		let prefilledExtras: any = {};
		if(prefilledData.extras && (prefilledData.extras).length > 0){
			for(let extra of prefilledData.extras){
				if(this.settingsObj?.extras[extra?.id]){
					prefilledExtras[extra.id] = extra;
					let newControl = this.frmBldr.group({
						id: [extra.id],
						name: [extra.name ? extra.name : (this.settingsObj.extras[extra.id] && this.utilServ.getFormParamName(this.settingsObj.extras[extra.id]))],
						quantity: ((extra.quantity) && extra.quantity <= (this.settingsObj?.extras[extra.id])?.quantity_based) ? extra.quantity : 1,
						apply_on_bookings: [extra.apply_on_bookings ? extra.apply_on_bookings : (this.settingsObj.extras[extra.id] && (this.settingsObj.extras[extra.id]).apply_to_bookings)],
						recurring: []
					});
					extrasFormArray.push(newControl);
				}
			}
		}
		this.prefilledFormParams.extras = prefilledExtras;
	}

	// Items(form 2) with packages functions
	/**
	 * Prefilled form 2 items,packages and package addons
	 * @param prefilledData: prefilled data
	 */
	private prefilledItemsForm2(prefilledData: any): void {
		let itemsFormArray: any = <FormArray>this.BKFrm.controls['items'];
		if(prefilledData && prefilledData.items && (prefilledData.items).length > 0){
			(prefilledData.items).forEach((item: any, index: any) => {
				let itemObj: any = {
					id: +item.id,
					name: item.name,
					quantity: item.quantity,
					enable_quantity_based: item.enable_quantity_based,
					quantity_based: item.quantity_based,
					can_combine: item.can_combine,
					count_multiple_spots: item.count_multiple_spots,
					packages: this.frmBldr.array([])
				};
				itemsFormArray.push(this.frmBldr.group(itemObj));
				// Selected package addon based on item and package id
				if(this.prefilledFormParams && this.prefilledFormParams.items && !this.prefilledFormParams.items[index]){
					this.prefilledFormParams.items[index]={};
				}
				// Selected package addon based on item and package id
				if(this.prefilledFormParams && this.prefilledFormParams.items && !this.prefilledFormParams.items[index][item.id]){
					this.prefilledFormParams.items[index][item.id]={};
				}
				// Packages
				if(item.packages && (item.packages).length > 0){
					let packageArray: any = <FormArray>(<FormGroup>(itemsFormArray).controls[index]).controls['packages'];
					(item.packages).forEach((pckg: any, pckgIndex: any) => {
						let packageObj: any = {
							id: +pckg.id,
							name: pckg.name,
							quantity: pckg.quantity,
							enable_quantity_based: pckg.enable_quantity_based,
							quantity_based: pckg.quantity_based,
							can_combine: (this.formElemCombStatus && this.formElemCombStatus['package'] && this.formElemCombStatus['package'][pckg.id]) ? this.formElemCombStatus['package'][pckg.id] : pckg.can_combine,
							count_multiple_spots: pckg.count_multiple_spots,
							package_addons: this.frmBldr.array([])
						};
						packageArray.push(this.frmBldr.group(packageObj));
						// Selected package addon based on item and package id
						if(this.prefilledFormParams.items[index][item.id] && !this.prefilledFormParams.items[index][item.id][pckg.id]){
							this.prefilledFormParams.items[index][item.id][pckg.id]={};
						}
						// Package addons
						if(pckg.package_addons && (pckg.package_addons).length > 0 ){
							for(let packageAddon of pckg.package_addons){
								let packageAddonObj: any = {
									id: packageAddon.id,
									name: packageAddon.name,
									enable_quantity_based: packageAddon.enable_quantity_based,
									quantity_based: packageAddon.quantity_based,
									quantity: packageAddon.quantity
								};
								let packageAddonArray = (<FormArray>(<FormGroup>(packageArray).controls[pckgIndex]).controls['package_addons']);
								packageAddonArray.push(this.frmBldr.group(packageAddonObj));
								// Selected package addon based on item and package id
								if(this.prefilledFormParams.items[index][item.id][pckg.id] && !this.prefilledFormParams.items[index][item.id][pckg.id][packageAddon.id]){
									this.prefilledFormParams.items[index][item.id][pckg.id][packageAddon.id] = packageAddon;
								}
							}
						}
					});
				}
			});
		}
	}

	// Item(Form 3) with addons function
	/**
	 * Prefilled items with addons for form 3
	 * @param prefilledData: prefilled data
	 */
	private prefilledItemsForm3(prefilledData: any): void {
		let itemsFormArray: any = <FormArray>this.BKFrm.controls['items'];
		if(prefilledData && prefilledData.items && (prefilledData.items).length > 0){
			(prefilledData.items).forEach((item: any, index: any) => {
				let itemObj: any = this.frmBldr.group({
					id: +item.id,
					name: item.name,
					quantity: item.quantity,
					enable_quantity_based: item.enable_quantity_based,
					quantity_based: item.quantity_based,
					can_combine: (this.formElemCombStatus && this.formElemCombStatus['items'] && this.formElemCombStatus['items'][item.id]) ? this.formElemCombStatus['items'][item.id] : item.can_combine,
					count_multiple_spots: item.count_multiple_spots,
					addons: this.frmBldr.array([])
				});
				itemsFormArray.push(itemObj);
				// Selected package addon based on item and addon id
				if(this.prefilledFormParams && this.prefilledFormParams.items && !this.prefilledFormParams.items[index]){
					this.prefilledFormParams.items[index] = {};
				}
				// Selected package addon based on item and package id
				if(this.prefilledFormParams && this.prefilledFormParams.items && !this.prefilledFormParams.items[index][item.id]){
					this.prefilledFormParams.items[index][item.id]={};
				}
				if(item.addons && (item.addons).length > 0){
					let addonArray: any = <FormArray>(<FormGroup>(<FormArray>this.BKFrm.controls['items']).controls[index]).controls['addons'];
					for(let addon of item.addons){
						let addonObj: any = this.frmBldr.group({
							id: addon.id,
							name: addon.name
						});
						addonArray.push(addonObj);
						// Selected package addon based on item and package id
						if(this.prefilledFormParams.items[index][item.id] && !this.prefilledFormParams.items[index][item.id][addon.id]){
							this.prefilledFormParams.items[index][item.id][addon.id] = addon;
						}
					}
				}
			});
		}
	}

	// Pricing area control (Form 4)
	/**
	 * Create pricing area control
	 */
	private createPricingAreaControl(): void {
		if(this.BKFrm.controls['area_parameter']){
			this.BKFrm.removeControl('area_parameter');
		}
		if(this.settings && this.settings.form_data && this.settings.form_data.validate_pricing_param && this.settings.form_data.validate_pricing_param == 'yes'){
			this.BKFrm.addControl('area_parameter', this.frmBldr.group({
				id : [],
				name : [null],
				quantity : [null, [Validators.required]]
			}));
		} else {
			this.BKFrm.addControl('area_parameter', this.frmBldr.group({
				id : [],
				name : [null],
				quantity : [null]
			}));
		}
	}
	/**
	 * Prefilled area parameters
	 * @param prefilledData: prefilled data
	 */
	private prefilledPricingAreaParams(prefilledData: any): void {
		let areaParam: any = <FormGroup>this.BKFrm.controls['area_parameter'];
		if(prefilledData && prefilledData.area_parameter){
			if(this.settingsObj?.area_param[0]?.value[+prefilledData?.area_parameter?.id]){
				areaParam.patchValue({
					id: prefilledData.area_parameter.id,
					name: prefilledData.area_parameter.name ? prefilledData.area_parameter.name : (this.settingsObj.area_param[0]?.value[prefilledData.area_parameter.id] && this.utilServ.getFormParamName(this.settingsObj.area_param[0]?.value[prefilledData.area_parameter.id])),
					quantity: prefilledData.area_parameter.quantity
				});
				this.prefilledFormParams['area_param']={};
				this.prefilledFormParams['area_param'][prefilledData.area_parameter.id]=prefilledData.area_parameter;
			}
		}
	}

	// Customer details functions
	/**
	 * Login user
	 * @param flag: use for multi step form
	 */
	public async loginUser(flag: boolean = true, isShortForm: boolean = false): Promise<any> {
		let custFormGroup: any = <FormGroup> this.BKFrm.controls['customer'];
		let loginData: any = {
			username: (custFormGroup.value['email_id']).toLowerCase(),
			password: custFormGroup.value['password']
		};
		let loaderId: string = 'bkng-cust-details';
		let status = await this.authServ.login(loginData, { redirectUrl : '', loaderId : loaderId, isRedirect : false, isSession : false, isBooknow : true, isSetOldData : true });

		if(status){
			// Email and customer component reset the variables email exist
			if(this.emailComp){
				this.emailComp.emailExistVar = false;
			}
			if(this.customerComp){
				this.customerComp.emailExistVar = false;
			}

			this.initServ.reRender = false;
			// Current loggedin user info from browser local storage
			// If embed booking, on login event get the local storage info
			let currentUser = this.utilServ.appLocalStorage('currentUser', true);
			if(currentUser){
				// App redirection based on user role.
				if(currentUser.role != 'customer') {
					let appLink: string = '';
					switch (currentUser.role) {
						case 'provider': appLink = 'provider';
							break;
						case 'merchant': appLink = 'admin/dashboard';
							break;
						case 'staff': appLink = 'admin/dashboard';
							break;
					}
					window.location.href = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname) + '/' + appLink;
				}
				this.currentUserIp = currentUser.Ip;
				this.currentUserId = currentUser.id;
				this.BKFrm.patchValue({
					uid: currentUser.id,
					created_by: this.currentUserId,
					ip_address: this.currentUserIp,

				});
				// Customer group
				this.BKFrm.controls['customer'].patchValue({
					id: currentUser.id,
					// TODO: Removed using username from local storage
					// email_id: currentUser.username,
					email_id : this.initServ?.userInfo?.email_id,
					customer_type: 'existing customer'
				});
				// Set the current user data
				this.currentUser = currentUser;
				// Customer details
				this.getCustomerDetailsApi();
				if(!isShortForm){
					this.getReferralDiscount();
					// Get daily discount according to customer
					this.getDailyDiscounts();
				}
				this.bkngCustSecServ.rebuildCustomFields(this.BKFrm, this.cDRef, this.bookingType);
				this.setMultiStepFormSteps();
				this.addRemoveStepWiseValOnCustFieldsWithDelay();
			}
			setTimeout(()=>{
				this.loader.hide(loaderId);
				this.initServ.reRender = true;
				if(isShortForm){
					this.gotoBookNow();
				} else {
					if(flag && this.formLayout == 'two_step'){
						this.goToStepTwo();
					}
				}
				this.cDRef.detectChanges();
			}, 500);
		} else{
			if(custFormGroup.controls['password']){
				custFormGroup.controls['password'].markAsTouched();

			}
			if(this.utilServ.inIframe(this.utilServ.embedStatus)){
				if(typeof(parentToTop) !== 'undefined'){ parentToTop() }
			}
			this.loader.hide(loaderId);
		}
	}
	/**
	 * customer change
	 * Check the discount
	 * User email saved to `Mail chimp` and 'Campaign` server
	 */
	public custmrChng(variationId: string = ''): void {
		let custFormGroup: any = <FormGroup> this.BKFrm.controls['customer'];
		// Called this function to refresh the new user data.
		if(this.customerComp){
			this.customerComp.buildCustomerDetails();
		}
		if(custFormGroup.value['email_id']){
			this.addBkngServ.addEmailToMailChimp(this.BKFrm.value);
			this.addBkngServ.addContactToCampaign(this.BKFrm.value);
			let typeSlugObj: TypeSlugObj = { type: 'booking', slug: 'booking-form' };
			let interval: number | null = null;
			// This code will run for short form
			if (variationId && variationId.length > 0) {
				typeSlugObj = this.getShortFormType();
				interval = 20000;
			}
			this.leadsServ.addContactToLead(this.BKFrm, typeSlugObj, interval);
		}
		// Email change discount re apply
		if((<FormGroup>this.BKFrm.controls['coupon']).controls['code'].value){
			this.reApplyCoupon('email');
		} else {
			// Coupon applied, check then gift card and referral
			this.couponApply(true, 'email');
		}
		if(this.customerComp){
			this.customerComp?.refresh();
		}
	}
	/**
	 * Retrieves the type and slug for a short form based on the current context.
	 * If the popupId exists, it returns type 'popup_short_form', otherwise 'theme_builder_short_form'.
	 * Slug is generated by appending the section_id to the type.
	 * @returns {TypeSlugObj} - An object containing the type and slug for the short form.
	 */
	public getShortFormType(): TypeSlugObj {
		let type: string = this.popupId ? 'popup_short_form' : 'theme_builder_short_form';
		return {
			type: type,
			slug: type+'_'+this.secSett?.section_id
		}
	}
	/**
	 * Check the email exist validation
	 */
	public checkEmailVali(emptyPwd:boolean=true): void {
		let customerGroup: any = <FormGroup>this.BKFrm.controls['customer'];
		if(customerGroup.controls['email_id'].value && !this.BKFrm.controls['uid'].value){
			this.customerComp?.emailExistCtrl(customerGroup.controls['email_id'], 'single', false, emptyPwd);
			customerGroup.controls['email_id'].markAsTouched();
			this.loader.hide();
		}
		// Multiple emails
		if(customerGroup.controls['emails'].value && (customerGroup.controls['emails'].value).length > 0){
			let emails: any = <FormArray>customerGroup.controls['emails'];
			let emailFromGroup: any = <FormGroup>emails.controls[0];
			if(emailFromGroup.controls['value'].value){
				this.customerComp?.emailExistCtrl(emailFromGroup.controls['value'], 'all',false, emptyPwd);
				emailFromGroup.controls['value'].markAsTouched();
			}
			this.loader.hide();
		}
	}

	// Discount section function
	/**
	 * Prefilled coupon
	 */
	private prefilledCoupon(): void {
		let couponGroup: any = <FormGroup>this.BKFrm.controls['coupon'];
		// Patch coupon group control
		couponGroup.patchValue({
			applicable_with_gift_card: this.prefilledData.coupon.applicable_with_gift_card,
			applicable_with_referral: this.prefilledData.coupon.applicable_with_referral,
			code: this.prefilledData.coupon.code,
			discount: this.prefilledData.coupon.discount,
			discount_type: this.prefilledData.coupon.discount_type,
			half_discount: this.prefilledData.coupon.half_discount,
			id: this.prefilledData.coupon.id,
			provider_discount: this.prefilledData.coupon.provider_discount,
			provider_discount_type: this.prefilledData.coupon.provider_discount_type,
			recurring: this.prefilledData.coupon.recurring,
			apply_on_bookings: this.prefilledData.coupon.apply_on_bookings
		});
		// Check the following fields and set the control value
		let checkFields: string[] = ['min_order', 'max_order', 'apply_order_on_adjusted', 'apply_freq_disc_with_coupon', 'override_freq_cancel_settings', 'charge_onetime_cancellation', 'cancellation_after_appt', 'prepay_rec_booking', 'prepay_bookings_count'];
		if(checkFields && checkFields.length > 0){
			for(let field of checkFields){
				if(this.prefilledData.coupon[field]){
					couponGroup.controls[field].setValue(this.prefilledData.coupon[field]);
				}
			}
		}
		// Re-apply coupon
		this.reApplyCoupon('coupon-amount');
	}
	/**
	 * Re-apply coupon
	 * @param msgType: message type
	 */
	private reApplyCoupon(msgType: any): void {
		let couponGroup: any = <FormGroup>this.BKFrm.controls['coupon'];
		if(couponGroup.controls['code'].value){
			if(!this.prefilledData || (this.prefilledData && this.prefilledData.coupon.code != couponGroup.controls['code'].value)){
				let couponCodeVal =couponGroup.controls['code'].value;
				if(couponCodeVal && (!this.isStepOneValid || this.BKFrm.controls['arrival_time'].valid)){
					this.reApplyCouponComnPart(couponCodeVal, msgType);
				}
			} else{
				if(this.prefilledData && this.prefilledData.coupon.code && this.couponPerm && (!this.isStepOneValid || this.BKFrm.controls['arrival_time'].valid)){
					this.reApplyCouponComnPart(this.prefilledData.coupon.code, msgType);
				}
			}
		} else {
			// We are use setTimeout, because on location change  discount section reload and this code execute before, to avoid this condition we used the setTimeout
			setTimeout(()=>{
				let couponEl:any = document.getElementById('couponCodeVal');
				// eslint-disable-next-line no-unsafe-optional-chaining
				if(this.couponCode && this.couponPerm && (!this.isStepOneValid || this.BKFrm.controls['arrival_time'].valid) && (!couponEl?.value || (couponEl?.value && ((couponEl?.value).toLowerCase()) == (this.couponCode).toLowerCase()))){
					this.reApplyCouponComnPart(this.couponCode, msgType);
				}
			},0);
		}
	}
	/**
	 * Part of Re-apply coupon
	 * @param couponCodeVal
	 * @param msgType
	 */
	private reApplyCouponComnPart(couponCodeVal: any, msgType: any): void {
		let couponEl:any = document.getElementById('couponCodeVal');
		if(couponEl){
			couponEl.value = couponCodeVal;
		}
		this.utilServ.triggerEvnt(couponEl,'focusout');
		if(this.discountComp){
			this.discountComp.codeValue = couponCodeVal;
			this.discountComp?.applyCoupon('code', couponCodeVal, msgType);
		}
	}
	/**
	 * Coupon apply check the gift card and referral
	 * @param status: true/false
	 */
	public couponApply(status: boolean, msgType: any = 'common'): void {
		if(status){
			if((<FormGroup>this.BKFrm.controls['gift_card']).controls['code'].value){
				this.reApplyGiftCardDiscount(msgType);
			} else if (this.BKFrm.controls['referral_amount'].value)	{
				this.reApplyReferral(msgType);
			}
		}
		this.calcTotalPrice();
	}
	/**
	 * Reset coupon
	 * @param msgObj
	 */
	public resetCoupon(msgObj: any): void {
		if(msgObj.msgType){
			if((<FormGroup>this.BKFrm.controls['coupon']).controls['discount'].value){
				if(msgObj.showMsgOnCouponRemove){
					let msg = this.bkngFormServ.resetCouponMsgs(msgObj.msgType);
					// this.bkngFormServ.msgPopup(msg);
					this.bkngFormServ.newMsgPopup(msg);
				}
			}
			this.BKFrm.controls.coupon.patchValue(this.bkngFormServ.couponNullPatch());
			// After reset coupon, checked the giftcard and referral
			this.couponApply(true, msgObj.msgType);
		}
		this.calcTotalPrice();
	}
	/**
	 * Function to reset the number of recurring bookings in case of coupon that have precharge settings.
	 */
	public checkPreChargeIfCoupon(data: any): void {
		if(this.serviceProviderComp){
			this.serviceProviderComp.resetRecurringDates(data);
		}else if(this.multiServiceProviderComp){
			this.multiServiceProviderComp.resetRecurringDates(data);
		}
	}
	/**
	 * Re-apply gift card discount
	 * @param msgType: any
	 */
	private reApplyGiftCardDiscount(msgType: any): void {
		setTimeout(()=> {
			let code = (<FormGroup>this.BKFrm.controls['gift_card']).controls['code'].value;
			if(code){
				if(this.discountComp){
					this.discountComp?.applyGiftCard(code, msgType);
				} else{
					let couponGroup: any = <FormGroup>this.BKFrm.controls['coupon'];
					if(couponGroup.controls['id'].value){
						if(couponGroup.controls['applicable_with_gift_card'].value){
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							this.discountComp!.getGiftCardByCode(code, msgType);
						}
					} else{
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						this.discountComp!.getGiftCardByCode(code, msgType);
					}
				}
			} else{
				this.reApplyReferral(msgType);
			}
		}, 0);
	}
	/**
	 * Gift card successfully applied
	 * @param value: value
	 */
	public giftCardSuccessfullyApplied(value: any): void {
		if(value){
			this.priceLocalVar.giftCardTotalAmount = value.amount;
		} else {
			this.priceLocalVar.giftCardTotalAmount = 0;
		}
		if(value.status){
			if(this.BKFrm.controls['referral_amount'].value){
				this.reApplyReferral('common');
			}
		}
		this.calcTotalPrice();
	}
	/**
	 * Reset the gift card based of msgType
	 * @param msgType: message type
	 */
	public resetGiftCard(msgType: any): void {
		if(msgType){
			if((<FormGroup>this.BKFrm.controls['gift_card']).controls['id'].value){
				let msg = this.bkngFormServ.resetGiftCardMsgs(msgType);
				// this.bkngFormServ.msgPopup(msg);
				this.bkngFormServ.newMsgPopup(msg);
			}
			if(this.discountComp){
				this.discountComp.removeGiftCard();
			} else{
				this.BKFrm.controls.gift_card.patchValue(this.bkngFormServ.giftcardNullPatch());
				let value = { amount: null, status: true};
				this.giftCardSuccessfullyApplied(value);
			}
			if(this.BKFrm.controls['referral_amount'].value){
				this.reApplyReferral(msgType);
			}
		}
		this.calcTotalPrice()
	}
	/**
	 * Re-apply referral
	 * @param msgType: any
	 */
	public reApplyReferral(msgType: any): void {
		setTimeout(()=>{
			let value = this.BKFrm.controls['referral_amount'].value;
			let total = +this.BKFrm.controls['total'].value;
			let serviceTotalAmount = this.utilServ.roundToTwo((+total) - (+this.priceLocalVar?.displayGiftCardAmount));
			if(value && ((+value) > +serviceTotalAmount || (+this.referralAmountUserId !=  +this.BKFrm.controls['uid'].value) || msgType == 'email')){
				this.resetReferral(msgType);
			}
		},0);
	}
	/**
	 * Reset the referral discount
	 * @param msgType
	 */
	public resetReferral(msgType: any): void {
		if(msgType){
			if(this.BKFrm.controls['referral_amount'].value){
				let msg = this.bkngFormServ.resetReferralMsgs(msgType);
				// this.bkngFormServ.msgPopup(msg);
				this.bkngFormServ.newMsgPopup(msg);
			}
			if(this.discountComp){
				this.discountComp?.removeReferral();
			} else{
				this.BKFrm.controls['referral_amount'].setValue(null);
			}
		}
		this.calcTotalPrice();
	}
	/**
	 * reset id of user that has referral
	 * @param uId: userId
	 */
	public resetReferralUser(uId: any): void {
		this.referralAmountUserId = uId;
		if((+this.referralAmountUserId !=  +this.BKFrm.controls['uid'].value) && this.BKFrm.controls['referral_amount'].value){
			this.resetReferral('email');
		}
	}
	/**
	 * Referral discount successfully applied
	 */
	public referralApply(): void {
		this.calcTotalPrice();
	}

	/**
	 * Check the visibility of first step(Multi step)
	 * @param res
	 */
	private checkVisibilityFirstStep(res: any, isFormFive: boolean = false): void {
		this.isStepOneAvail = false;
		let data: any = null;
		let customFieldsStatus = null;
		if(this.apiServ.checkAPIRes(res)){
			customFieldsStatus = res.data;
		}
		let flag = false;
		if(isFormFive){
			data = this.multiStepFormSections['step_two'];
			if(data && data.length > 0){
				if(data.includes('frequency') && this.bkngFormServ.isFrequencyAvailable(this.settings.frequencies,this.selectedServiceType,this.selectedLocation,this.prefilledData, this.settings, this.bookingType)){
					/** check if multiple frequency available. **/
					flag = true;
				}
				else if(data.includes('customer_details') || data.includes('payment_info')){
					flag = true;
				} else{
					let extraCustomFields: any = this.addBkngServ.checkExtraCustomFieldsOnMultiStep(data, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.BKFrm, this.prefilledFormParams, this.bookingType, customFieldsStatus, this.section?.extras, this.pageSett);
					if(extraCustomFields){
						flag = extraCustomFields;
					}
				}
				let keyInfoSpecialNote: any = this.addBkngServ.checkKeyInfoSpecialNoteOnMultiStep(data, this.BKFrm, this.section, this.pageSett);
				if(keyInfoSpecialNote){
					flag = keyInfoSpecialNote;
				}
			}
			if(flag){
				this.multistepFormSteps = ["step_one", "step_two", "step_three", "step_four"];
			}else{
				this.multistepFormSteps = ["step_one", "step_three", "step_four"];
			}
			this.isStepAvailFormFive = flag;
		} else {
			data = this.multiStepFormSections['step_one'];
			if(data && data.length > 0){
				/// Location exist for form
				if(this.locationLayout != 'no_location'){
					flag = true;
				}
				/** check if specific sections exist for step **/
				else if(data.includes('email') || data.includes('customer_details') || data.includes('payment_info')){
					flag = true;
				}
				/** check to if multiple service category available **/
				else if((this.bkngFormServ.isServiceAvailable(this.settings.service_category, this.selectedLocation, this.prefilledData, this.settings, this.bookingType)) || this.bkngFormServ.isServiceAvailable(this.settings.service_category, this.selectedLocation, this.prefilledData, this.settings, this.bookingType, true)){
					flag = true;
				}
				/** check if multiple service category available. **/
				else if(data.includes('frequency') && this.bkngFormServ.isFrequencyAvailable(this.settings.frequencies,this.selectedServiceType,this.selectedLocation,this.prefilledData, this.settings, this.bookingType)){
					flag = true;
				} else {
					let extraCustomFields: any = this.addBkngServ.checkExtraCustomFieldsOnMultiStep(data, this.settings, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.BKFrm, this.prefilledFormParams, this.bookingType, customFieldsStatus, this.section?.extras, this.pageSett);
					if(extraCustomFields){
						flag = extraCustomFields;
					}
				}
				let keyInfoSpecialNote: any = this.addBkngServ.checkKeyInfoSpecialNoteOnMultiStep(data, this.BKFrm, this.section, this.pageSett);
				if(keyInfoSpecialNote){
					flag = keyInfoSpecialNote;
				}
			}
			if(flag){
				this.multistepFormSteps = ["step_one", "step_two", "step_three", "step_four"];
			} else {
				// reset steps
				this.multistepFormSteps = ["step_two", "step_three", "step_four"];
				this.addBkngServ.refreshMultiStepSlider();
			}
			this.isStepOneAvail = flag;
			this.cDRef.detectChanges();
		}
	}
	/**
	 * Move to specific step
	 * @param step: step (step_one, step_two, step_three, first)
	 */
	public moveToStep(step: any): void {
		//If form five has one less step
		if(step == 'step_three'){
			if(!this.BKFrm.controls['arrival_time'].value){
				this.BKFrm.controls['arrival_date'].setValue(null);
			}
			if(step == 'step_three' && (+this.formId == 5 && !this.isStepAvailFormFive)){
				step = 'step_two';
			}
		}
		if(step == 'first'){
			if(this.isMultiIndsFrm){
				this.setMultiStpFrmFrstStp.emit(false);
			} else {
				this.activeStep = 0;
			}
		} else {
			switch(step){
				case 'step_one':
					this.activeStep = 0;
				break;
				case 'step_two':
					if(!this.isStepOneAvail && this.BKFrm?.value?.form_id != 5){
						this.activeStep = 0;
					} else {
						this.activeStep = 1;
					}
				break;
				case 'step_three':
					if(!this.isStepOneAvail && this.BKFrm?.value?.form_id != 5){
						this.activeStep = 1;
					} else {
						this.activeStep = 2;
					}
				break;
			}
		}
		this.bkngCustSecServ.resetValOnMultiHeadChange(this.activeStep)
		window.scrollTo({top: 0, behavior: 'smooth'});
		this.addBkngServ.refreshMultiStepSlider();
	}
	/**
	 * Validate each step of multi stepper form
	 */
	public validateSteps(step: any): boolean {
		let validate: any = false;
		switch(step){
			case 'step_one':
				validate = this.validateStepOne(step, this.selectedServiceType);
			break;
			case 'step_two':
				validate = this.checkFormValidation(step);
			break;
			case 'step_three':
				if(this.BKFrm.controls['arrival_date'].value && this.BKFrm.controls['arrival_time'].value){
					validate = true;
				}else{
					this.BKFrm.controls['arrival_date'].markAsTouched();
					this.BKFrm.controls['arrival_time'].markAsTouched();
					// this.bkngFormServ.scrollToSpecificEle('provider-section-id');
					let msg = 'Please select the date for your booking.';
					if(this.BKFrm.controls['arrival_date'].value){
						msg = 'Please select the slot for your booking.'
					}
					this.toastr.error(msg);
				}
			break;
		}
		this.cDRef.detectChanges();
		return validate;
	}
	/**
	 * Validate step one
	 * @param step
	 * @returns
	 */
	private validateStepOne(step: any, selectedService: any= null): boolean {
		let validate: boolean = false;
		let data: any;
		switch(this.formLayout){
			case 'multi_step':
				data = this.multiStepFormSections[step];
			break;
			case 'two_step':
				data = this.twoStepFormSections[step];
			break;
			case 'one_step':
				data = this.oneStepFormSections;
			break;
		}
		if(this.BKFrm.controls['zipcode'].valid && this.BKFrm.controls['location_id'].valid){
			if(this.BKFrm.controls['service_category'].value && this.BKFrm.controls['frequency'].value){
				if(this.BKFrm.controls['is_service_hourly'].value == 'yes'){
					validate = this.validateHourlyService(selectedService, data, validate);
				} else {
					validate = this.validateCustomFieldsAndCustomerInfo(data);
				}
			} else {
				let errorMsg = 'Please check your industry settings. It seems there may be a service category or frequency missing.';
				this.toastr.error(errorMsg);
			}
		} else {
			this.utilServ.scrollToSpecificEle(this.section.location.id);
			this.BKFrm.controls['zipcode'].markAsTouched();
			let msg: string = (this.BKFrm.controls['location_type'].value == 'ML') ? 'Please select the location for this booking.' : 'Please fill in the location for this booking.';
			this.toastr.error(msg);
			if(this.locationComp){
				this.locationComp?.refresh();
			}
		}
		return validate;
	}
	/**
	 * Validate the hourly service
	 * @param selectedService
	 * @param data
	 * @param validate
	 * @returns
	 */
	validateHourlyService(selectedService: any, data: any, validate: boolean){
		if(this.BKFrm.controls['service_hourly_value'].value || this.calCmnFuncServ.isHourlyServPriceBasedOnParam(selectedService) ){
			validate = this.validateCustomFieldsAndCustomerInfo(data);
		} else{
			let msg = 'Please select time duration';
			// Here use the this.selectedServiceType purposally, because selectedService param comes only in case of multi step
			if(this.calCmnFuncServ.isHourlyServPriceBasedOnParam(this.selectedServiceType)){
				msg = 'Kindly review your parameter selection to ensure that the length is not set to zero.'
			}
			if(this.formLayout != 'multi_step'){
				// this.bkngFormServ.msgPopup(msg, true, this.section?.service?.id);
				this.bkngFormServ.newMsgPopup(msg, false, '', true, this.section?.service?.id);
			} else {
				this.utilServ.scrollToSpecificEle(this.section?.service?.id);
			}
			this.toastr.error(msg);
		}
		return validate;
	}
	/**
	 * Validate custom fields and customer info
	 * @param data
	 * @returns
	 */
	private validateCustomFieldsAndCustomerInfo(data: any): any {
		let validate: boolean = false;

		validate = this.validateFrmServ.isCustomFieldValid(this.BKFrm, data);
		// Validate of custom fields
		if(validate){
			if(this.formLayout == 'multi_step'){
				// Validate of customer details
				validate = this.validateCustomerDetails(data);
			} else {
				// Validate of customer details(Working on single and two step form layout)
				validate = this.validateStepOneEmail(data);
				// Add contact to lead
				this.addContactToLead();
			}
		} else {
			// Refresh the custom field component
			if(this.customFieldsComp){
				this.toastr.error('Please fill the required fields marked in red.');
				this.customFieldsComp?.markAllCustFieldsAsTouched();
				this.cDRef.detectChanges();
			}
		}
		return validate;
	}
	/**
	 * Validate customer details
	 * @param step
	 * @returns
	 */
	private validateCustomerDetails(stepData: any){
		let flag = false;
		let customerGroup: any = <FormGroup>this.BKFrm.controls['customer'];
		let values = this.bkngFormServ.submitPartOne(this.BKFrm.value);
		let emailExist = values.emailExist;
		let phoneExist = values.phoneExist;
		if(stepData && stepData.includes('customer_details') || stepData.includes('personal_details')  || stepData.includes('address_details') || stepData.includes('email')){
			let emailIdStatus: boolean = (customerGroup.controls['email_id'].value && (customerGroup.controls['email_id'].errors && customerGroup.controls['email_id'].errors['emailExists'])) ? true : false;
			// Email component, check the emailExistVar value because some time setError not set the error
			if(stepData.includes('email') && this.emailComp && this.emailComp.emailExistVar){
				emailIdStatus = true;
			} else {
				// Customer component
				if(this.customerComp && this.customerComp.emailExistVar){
					emailIdStatus = true;
				}
			}
			let pwdStatus = customerGroup.controls['password'] ? customerGroup.controls['password'].valid : true;
			if(customerGroup.controls['email_id'].valid && !this.prefilledData){
				pwdStatus = true;
			}
			if(this.prefilledData){
				if(this.prefilledData.uid){
					emailIdStatus = true;
				}
				if(!this.prefilledData.uid || this.currentUser){
					pwdStatus = true;
				}
			}
			if(((customerGroup.controls['first_name'].valid && customerGroup.controls['last_name'].valid) || stepData.includes('email')) && pwdStatus){
				if(emailIdStatus && !this.currentUser){
					this.loginUser();
				} else {
					flag = this.validateFrmServ.validateCustomerDetailsEmail(stepData,emailExist,phoneExist,this.BKFrm,this.section);
					if(!flag && this.customerComp){
						this.customerComp?.refresh();
					}
				}
			} else{
				flag = this.validateFrmServ.validateCustomerDetails(stepData, pwdStatus, emailIdStatus, customerGroup, this.section,this.BKFrm);
				if(!flag && this.customerComp){
					this.customerComp?.refresh();
				}
			}
		} else {
			flag = true;
		}
		if(flag){
			this.validateFrmServ.validateNotes(stepData, this.BKFrm, this.section)
		}
		return flag;
	}
	/**
	 * Validate email for step two form
	 * @returns
	 */
	private validateStepOneEmail(stepData: any){
		let flag = false;
		let customerGroup: any = <FormGroup>this.BKFrm.controls['customer'];
		let emailIdStatus: boolean = (customerGroup.controls['email_id'].value && (customerGroup.controls['email_id'].errors && customerGroup.controls['email_id'].errors['emailExists'])) ? true : false;
		// Email component, check the emailExistVar value because some time setError not set the error
		if(stepData.includes('email') && this.emailComp && this.emailComp.emailExistVar){
			emailIdStatus = true;
		} else {
			// Customer component
			if(this.customerComp && this.customerComp.emailExistVar){
				emailIdStatus = true;
			}
		}
		let pwdStatus = customerGroup.controls['password'] ? customerGroup.controls['password'].valid : true;
		if(customerGroup.controls['email_id'].valid && !this.prefilledData){
			pwdStatus = true;
		}
		if(this.prefilledData){
			if(this.prefilledData.uid){
				emailIdStatus = true;
			}
			if(!this.prefilledData.uid || this.currentUser){
				pwdStatus = true;
			}
		}
		if((customerGroup.controls['email_id'].value && pwdStatus) || (this.formLayout !='one_step' && this.settings.form_data.enable_email_on_first_step && this.settings.form_data.enable_email_on_first_step == 'no')){
			if(emailIdStatus && !this.currentUser){
				if(this.formLayout =='one_step' || (this.settings.form_data.enable_email_on_first_step && this.settings?.form_data?.enable_email_on_first_step != 'no') || (!this.settings?.form_data?.enable_email_on_first_step) || (this.BKFrm.controls['uid'].value && !this.currentUser)){
					this.loginUser();
				}else{
					//add this code to solve the issue, when email comes from url param and not enable on step one.
					if((this.settings.form_data.enable_email_on_first_step && this.settings?.form_data?.enable_email_on_first_step == 'no') || (!this.settings?.form_data?.enable_email_on_first_step)){
						this.goToStepTwo();
					}
				}
			} else {
				flag = true;
				if(this.formLayout == 'one_step'){
					this.submitBKFrm();
				} else {
					this.goToStepTwo();
				}
			}
		} else {
			flag=false;
			if(customerGroup.controls['password']){
				customerGroup.controls['password'].markAsTouched();
			}
			customerGroup.controls['email_id'].markAsTouched();
			let sectionId = '';
			let userSec: any;
			if(this.BKFrm.value['location_type'] == 'SA'){
				userSec = this.section?.customer_details;
			} else {
				userSec = this.section?.personal_details;
			}
			let msg = "Please fill in the customer's valid email.";
			if(this.emailComp){
				sectionId = this.section?.email?.email_id;
				this.emailComp?.refresh();
			} else if(this.customerComp){
				sectionId = userSec?.email_id;
				this.customerComp?.refresh();
			}
			if(emailIdStatus && !pwdStatus){
				if(this.emailComp){
					sectionId = this.section?.email?.password_id;
					this.emailComp?.refresh();
				} else if(this.customerComp){
					sectionId = userSec?.password_id;
					this.customerComp?.refresh();
				}
				msg = 'Please fill in the password.';
			}
			this.utilServ.scrollToSpecificEle(sectionId);
			this.toastr.error(msg);
		}
		return flag;
	}

	// Summary functions
	/**
	 * Convert the adjusted time in to hours/min
	 */
	private convertAdjustedTimeToHours(): void {
		this.priceLocalVar.adjustedTimeHours = 0;
		this.priceLocalVar.adjustedTimeMin = 0;
		let adjustedTime = this.BKFrm.controls['adjusted_time'].value;
		if(adjustedTime && adjustedTime >= 60){
			this.priceLocalVar.adjustedTimeHours = Math.floor(adjustedTime/60);
			this.priceLocalVar.adjustedTimeMin = adjustedTime%60;
		} else{
			this.priceLocalVar.adjustedTimeHours = 0;
			this.priceLocalVar.adjustedTimeMin = adjustedTime;
		}
	}

	/**
	 * Function to calculate the total without tax.
	 */
	public totalWithoutTax(): any{
		let tWTax = 0;
		let total = this.priceLocalVar?.displayTotal ? this.priceLocalVar?.displayTotal : 0;
		let salesTax = this.priceLocalVar.displaySaleTax ? this.priceLocalVar.displaySaleTax : 0;
		let giftCardAmount = this.priceLocalVar?.displayGiftCardAmount ? this.priceLocalVar?.displayGiftCardAmount : 0;
		let referralAmount = this.priceLocalVar?.displayReferralDiscount ? this.priceLocalVar?.displayReferralDiscount : 0;
		tWTax = this.utilServ.roundToTwo(total - salesTax);
		tWTax = this.utilServ.roundToTwo(tWTax - giftCardAmount);
		tWTax = this.utilServ.roundToTwo(tWTax - referralAmount);
		if(tWTax < 0){
			tWTax = 0;
		}
		return tWTax;
	}

	/**
	 * Function to calculate all calculations.
	 * Its pricing calculation main function.
	 */
	public calcTotalPrice(){
		if(this.isReadyToLoad){
			// call the price calculation base function
			this.calcTotalPriceBase();
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Calculate price and provider payment.
	 */
	public calcTotalPriceBase(recFirstBkngId: any = null){
		// call the price calculation function
		let inptObj : any = {
			settingsObj : this.settingsObj,
			selectedService : this.selectedServiceType,
			selectedFrequency : this.selectedFrequency,
			bookingType : this.bookingType,
			prefilledData : this.prefilledData,
			recFirstBkngId : recFirstBkngId,
			bookingId : this.bookingId,
			isStepOneValid : this.isStepOneValid
		};
		this.prvdrPayVar = this.priceCalServ.getCmnPrvdrPayVar; // provider pay local variables
		let outputCalcTotalPrice = this.priceCalServ.calcTotalPrice(this.priceLocalVar, this.prvdrPayVar, this.BKFrm.value, inptObj);
		// patch local variable
		this.priceLocalVar = outputCalcTotalPrice.priceLocalVar;
		// Patch form variables
		this.BKFrm.patchValue(this.priceCalServ.patchTotalPriceVar(outputCalcTotalPrice));
		/**
		 * If total amount changed.
		 * Re-applied the applied giftcard and referral.
		 */
		if(this.bookingType == 'reschedule'){
			this.rescTotalChngDetect();
		}else{
			this.addDraftTotalChngDetect();
		}
		this.BKFrm.controls['total'].setValue(this.priceLocalVar.displayTotal);
		// calculate giftcard and referral
		let giftCardReferralObj = this.dscntCalServ.calcGiftCardReferral(this.BKFrm.value, this.priceLocalVar);
		// patch variable
		this.priceLocalVar.displayGiftCardAmount = giftCardReferralObj.displayGiftCardAmount;
		this.priceLocalVar.displayReferralDiscount = giftCardReferralObj.displayReferralDiscount;
		this.priceLocalVar.displayFinalAmount = giftCardReferralObj.displayFinalAmount;
		let giftCardGroup = <FormGroup>this.BKFrm.controls['gift_card'];
		giftCardGroup.controls['discount'].setValue(this.priceLocalVar.displayGiftCardAmount);
		this.BKFrm.controls['final_amount'].setValue(this.priceLocalVar.displayFinalAmount);
		// call the provider payment calculation function
		let outputPrvdrTotalAmt = this.prvdrPayCalServ.calcPrvdrTotalAmt(this.BKFrm.value, this.priceLocalVar, this.prvdrPayVar, this.selectedServiceType);
		this.prvdrPayVar = outputPrvdrTotalAmt.prvdrPayVar;
		this.priceLocalVar.displayProviderAmount = outputPrvdrTotalAmt.displayProviderAmount;
		this.BKFrm.patchValue({
			service_provider_pay_overridden : outputPrvdrTotalAmt.BKFrmValue.service_provider_pay_overridden,
			provider_pay_overridden_value : outputPrvdrTotalAmt.BKFrmValue.provider_pay_overridden_value,
			provider_pay_overridden_type : outputPrvdrTotalAmt.BKFrmValue.provider_pay_overridden_type
		})
		// If booking type is reschedule and provider payment type is overriden
		if(this.bookingType == 'reschedule' && this.selectedServiceType && this.selectedServiceType.override_provider_pay && this.BKFrm.controls['override_provider_pay'].value){
			let outputCheckPrvdrOverrideObj = this.prvdrPayCalServ.checkProviderOverridePayment(this.priceLocalVar.displayProviderAmount, this.prefilledData);
			this.isPrvdrPayDifferent = outputCheckPrvdrOverrideObj.isPrvdrPayDifferent;
			this.prevPrvdrOvridePay = outputCheckPrvdrOverrideObj.prevPrvdrOvridePay;
		}
		// Reset provider ids
		this.resetProviderIds(outputPrvdrTotalAmt.BKFrmValue.provider_ids);

	}
	/**
	 * Detect change for add/draft form
	 */
	private addDraftTotalChngDetect(){
		let giftCardGroup = <FormGroup>this.BKFrm.controls['gift_card'];
		if(this.BKFrm.controls['total'].value){
			if(this.priceLocalVar.displayTotal != this.BKFrm.controls['total'].value){
				if(this.isStepOneValid || this.discountComp){
					if(giftCardGroup.controls['code'].value){
						let code = giftCardGroup.controls['code'].value;
						this.discountComp?.applyGiftCard(code, 'amount');
					}else if (this.BKFrm.controls['referral_amount'].value){
						this.reApplyReferral('amount');
					}
				}else{
					this.reApplyGiftcardReferal(giftCardGroup, 'amount');
				}
			}
		}
	}
	/**
	 * Detect  the change for rechedule
	 * @param firstTimeStatus
	 */
	private rescTotalChngDetect(){
		let giftCardGroup = <FormGroup>this.BKFrm.controls['gift_card'];
		if(this.BKFrm.controls['total'].value){
			if((this.priceLocalVar.displayTotal != this.BKFrm.controls['total'].value)){
				if(this.isStepOneValid || this.discountComp){
					if(giftCardGroup.controls['code'].value){
						let code = giftCardGroup.controls['code'].value;
						this.discountComp?.applyGiftCard(code, 'amount');
					}else if (this.BKFrm.controls['referral_amount'].value){
						this.reApplyReferral('amount');
					}
				}else{
					if(this.priceCalServ.isTotalDiff(this.prefilledData, this.priceLocalVar)){
						this.reApplyGiftcardReferal(giftCardGroup, 'amount');
					}
				}
			}else{
				if(this.priceCalServ.isTotalDiffOrFirstTimeStatus(this.firstTimeStatus, this.isStepOneValid, this.priceLocalVar, this.prefilledData)){
					this.firstTimeStatus = false;
					this.reApplyGiftcardReferal(giftCardGroup, 'amount');
				}
			}
		}
	}
	/**
	 * Reset the provider ids
	 * @param providerIds
	 */
	private resetProviderIds(providerIds: any){
		// Remove the provider_ids control for reset purpose.
		this.BKFrm.removeControl('provider_ids');
		// Add provider_ids control again.
		this.BKFrm.addControl('provider_ids',this.frmBldr.array([]));
		let providerIdsArray = <FormArray>(this.BKFrm.controls['provider_ids']);
		// set the provider_ids control
		if(this.utilServ.checkArrLength(providerIds)){
			for(let providerId of providerIds){
				providerIdsArray.push(new FormControl(providerId));
			}
		}
	}
	/**
	 * Reapply the giftcard and  referral accordingly
	 * @param giftCardGroup
	 */
	private reApplyGiftcardReferal(giftCardGroup: any, type: any){
		if(giftCardGroup.controls['code'].value){
			this.reApplyGiftCardDiscount(type);
		}else{
			this.reApplyReferral(type);
		}
	}

	// Submit form functions
	/**
	 * Submit single form
	 */
	public submitSingleFrm(): void {
		this.loader.show();
		// Check the email validation
		let step: string = 'step_one';
		if(this.formLayout == 'multi_step'){
			step = 'step_four';
			this.partCheckEmailVali(step);
		}
		// Form validation
		let validate: any;
		setTimeout(()=>{
			if(this.formLayout == 'multi_step'){
				validate = this.validateStepOne('step_four');
				if(validate){
					this.submitBKFrm();
				}
			} else {
				validate = this.validateStepOne('step_one');
			}
			if(!validate){
				this.loader.hide();
			}
		}, 500);
	}
	/**
	 * Submit step one form in case of two step form
	 */
	public submitStepOne(): void {
		// Check the email validation
		this.partCheckEmailVali('step_one');
		setTimeout(()=>{
			this.validateStepOne('step_one');
			this.addContactToLead();
		},500);
	}

	/**
	 * Check email validation for multi step case
	 * @param step: multi step
	 */
	public partCheckEmailVali(step: any) {
		let stepData: any;
		switch(this.formLayout){
			case 'multi_step':
				stepData = this.multiStepFormSections[step];
			break;
			case 'two_step':
				stepData = this.twoStepFormSections[step];
			break;
			case 'one_step':
				stepData = this.oneStepFormSections;
			break;
		}
		if(stepData){
			let customerGroup: any = <FormGroup>this.BKFrm.controls['customer'];
			if(stepData && (stepData.includes('email') || stepData.includes('customer_details') || stepData.includes('personal_details')) && !this.currentUser){
				// customerGroup.controls['email_id'].markAsTouched();
				if(stepData.includes('email') && this.emailComp){
					this.emailComp.emailExistControl(customerGroup.controls['email_id'], false, true);
				} else {
					this.checkEmailVali(false);
				}
			}
		}
	}
	/**
	 * Goto step one
	 */
	public goToStepOne(): void{
		this.isStepOneValid = false;
		// this.isAllDataLoaded = false;
		// this.isAllDataLoadedTwo = false;
		// this.allAvailableProviders = null;
		// this.availableSortedProviders = null;
		// this.providerWorkingHours = null;
		// this.providerBookings = null;
		// this.providerBreakTimes = null;
		// this.availableProviderIds = null;
		let serviceProviderArray = <FormArray>this.BKFrm.controls['provider_details'];
		if(this.utilServ.checkArrLength(this.BKFrm.controls['provider_details'].value)){
			serviceProviderArray.clear();
		}
		this.BKFrm.patchValue({
			non_available_provider : false,
			provider_type : 'random_provider',
			arrival_date : null,
			arrival_time : null,
			booking_date : null,
			same_day_booking : false
		});
		// Reset the value of tips, parking and bonus amount on service change
		this.addRemoveTipParkingBonusControl('tip', '', false);
		this.addRemoveTipParkingBonusControl('parking', '', false);
		this.addRemoveTipParkingBonusControl('bonus', '', false);
		if(this.selectedServiceType.expedited_charge && this.selectedServiceType.enable_expedited_charge){
			let serviceExpeditedAmount = this.selectedServiceType.expedited_charge;
			this.BKFrm.controls['expedited_amount'].setValue(serviceExpeditedAmount);
		} else{
			this.BKFrm.controls['expedited_amount'].setValue(null);
		}
		this.bkngCustSecServ.addRemoveStepWiseValOnCustFields(false, 'step_two');
		this.calcTotalPrice();
		this.secondStepActivate.emit(true);
		this.sidebarOffset = this.tempSidebarOffset;
		this.cDRef.detectChanges()
	}
	/**
	 * Check form validation
	 * @param step
	 * @returns
	 */
	public checkFormValidation(step: any = null, isShortForm: boolean = false): any {
		let validate: boolean = false;
		// Check validation according to the form id
		let formParams: any = this.validateFrmServ.validateFormParams(this.BKFrm, this.formId, this.formLayout, this.selectedServiceType, this.settings, this.buildFormParamsStatus, this.bookingType);
		let validStatus = formParams.validStatus;
		let validItemStatus = formParams.validItemStatus;
		let validAreaParam = formParams.validAreaParam;
		this.validPckgStatus = formParams.validPckgStatus;
		if(validStatus && validItemStatus && this.validPckgStatus && validAreaParam){
			if(this.formLayout == 'multi_step' && !isShortForm){
				// Validate of custom fields
				validate = this.validateFrmServ.isCustomFieldValid(this.BKFrm, this.multiStepFormSections[step]);
				if(validate){
					validate = this.validateCustomerDetails(this.multiStepFormSections[step]);
				}else{
					this.toastr.error('Please fill the required fields marked in red.');
				}
			} else {
				validate = true;
			}
		} else {
			let errorMsg = '';
			switch(+this.formId){
				case 1:
					if(!validStatus && !isShortForm){
						errorMsg = 'Please select the pricing parameters.';
					}
				break;
				case 2:
				case 3:
					if(!validItemStatus){
						this.isNoItemSelected = true;
						this.isNoPackageSelected = false;
						this.isNoAddonSelected = false;
						let sectionId = this.section?.packages?.sel_item_id;
						if(+this.formId == 3){
							sectionId = this.section?.addons?.sel_item_id;
						}
						errorMsg = 'Please select at least one item.';
						let extraItems:any = document.querySelectorAll('.selected-extra--item');
						let isExtraSelected =  false;
						if(extraItems && extraItems.length > 0){
							extraItems.forEach((element:any) => {
								if(element.classList.contains('selected-extra')){
									isExtraSelected = true;
									return;
								}
							});
						}
						if(isExtraSelected){
							// eslint-disable-next-line max-depth
							if(+this.formId == 2){
								errorMsg = 'Please select at least one package';
								this.isNoItemSelected = false;
								this.isNoPackageSelected = true;
								sectionId = this.section?.packages?.choose_pkg_id;
							}else if(+this.formId == 3){
								errorMsg = 'Please select at least one addon';
								this.isNoItemSelected = false;
								this.isNoAddonSelected = true;
								sectionId = this.section?.addons?.choose_addon_id;
							}
						}
						this.utilServ.scrollToSpecificEle(sectionId);
					} else if(!this.validPckgStatus){
						this.isNoPackageSelected = true;
						let sectionId = this.section?.packages?.choose_pkg_id;
						this.utilServ.scrollToSpecificEle(sectionId);
						errorMsg = 'Please select at least one package.';
					}
				break;
				case 4:
					if(!validAreaParam){
						let areaParam: any = <FormGroup>this.BKFrm.controls['area_parameter'];
						areaParam.controls['quantity'].markAsTouched();
						errorMsg = 'Please fill the area size.';
						let sectionId = this.section?.area_params?.id;
						this.utilServ.scrollToSpecificEle(sectionId);
						if(this.areaParamComp){
							this.areaParamComp?.refresh();
						}
					}
				break;
			}
			if(errorMsg){
				this.toastr.error(errorMsg);
			}
		}
		this.cDRef.detectChanges();
		return validate;
	}

	/**
	 * Go to step two
	 */
	public goToStepTwo(): void{
		let validate = this.checkFormValidation();
		if(validate){
			this.BKFrm.markAsUntouched();
			// Add required validation on 2nd step fields
			switch (this.formLayout) {
				case 'two_step':
					this.bkngCustSecServ.addRemoveStepWiseValOnCustFields(true, 'step_two')
					break;
				default:
					break;
			}
			this.isStepOneValid = true;
			let customerGroup: any = <FormGroup>this.BKFrm.controls['customer'];
			customerGroup.controls['email_id'].markAsUntouched();
			// set sidebar offser zero
			this.sidebarOffset = 0
			this.secondStepActivate.emit(false);
			// Refresh the custom field component
			if(this.customFieldsComp){
				this.customFieldsComp?.markAllCustFieldsAsUntouched();
			}
			/** scroll to top of page when goto second step **/
			window.scrollTo({top: 0, behavior: 'smooth'});
			if(this.utilServ.inIframe(this.utilServ.embedStatus)){
				this.bkngFormServ.scrollParentToTop()
			}
		}
	}
	/**
	 * Submit booking form
	 */
	public submitBKFrm(): void {
		this.loader.show()
		setTimeout(() => {
			/** This code is here to debug the case in which provider_ids array goes empty with booking. **/
			if(this.BKFrm.controls['provider_ids'].value && (this.BKFrm.controls['provider_ids'].value).length > 0){
				let tempProviderIdsArray = [];
				for(let providerId of this.BKFrm.controls['provider_ids'].value){
					tempProviderIdsArray.push(providerId);
				}
				this.BKFrm.controls['temp_provider_ids'].setValue(tempProviderIdsArray)
			}
			let values = this.bkngFormServ.submitPartOne(this.BKFrm.value);
			let emailExist = values.emailExist;
			let phoneExist = values.phoneExist;
			let step: any = null;
			if(this.formLayout == 'multi_step'){
				step = 'step_four';
			}
			let validate = this.checkFormValidation(step);
			let customerGroup: any = <FormGroup>this.BKFrm.controls['customer'];
			if(customerGroup.controls['email_id'].valid){
				customerGroup.removeControl('password');
			}
			if((this.formLayout != 'one_step' && this.formLayout != 'multi_step') || validate){
				if(this.BKFrm.valid && !emailExist && !phoneExist){
					this.isAcceptTcValid = this.checkAcceptTcValid();
					if(this.isAcceptTcValid){
						let BKFrm = this.bkngFormServ.submitPartTwo(this.BKFrm, this.priceLocalVar?.displayFinalAmount, this.prvdrPayVar);
						// Function to  check minimum limit for booking
						// Function to  check minimum limit for booking
						let chkminLimitData: any = {
							selectedService : this.selectedServiceType,
							selectedFrequency : this.selectedFrequency,
							prefilledData : this.prefilledData,
							bookingType : this.bookingType
						}
						let flag : boolean = this.bkngCmnFun.chkMinimumLimit(BKFrm.value, this.BKFrm, this.priceLocalVar, chkminLimitData);
						if(flag){
							// eslint-disable-next-line max-depth
							if(BKFrm.controls['payment_method'].value == 'new_credit_card'){
								this.generateToken(BKFrm);
							} else {
								this.saveBooking(BKFrm.value);
							}
						}else{
							this.reAssignParkingValue()
							this.loader.hide()
							this.bkngFormServ.scrollParentToTop()
						}
					} else {
						if(this.section?.accept_tc?.error_msg_content){
							this.toastr.error(this.section?.accept_tc?.error_msg_content);
						}else{
							this.toastr.error('Please accept the terms and conditions');
						}
						this.cDRef.detectChanges();
						this.loader.hide()
					}
				} else {
					let customerGroup: any = <FormGroup>this.BKFrm.controls['customer'];
					let areaParamGroup = <FormGroup>this.BKFrm.controls['area_parameter'];
					if(+this.formId == 4){
						areaParamGroup.controls['quantity'].markAsTouched();
					}
					if(!customerGroup.controls['email_id'].valid){
						customerGroup.controls['password'].markAsTouched();
					}
					this.validateFrmServ.sbmtElse(this.BKFrm, emailExist, phoneExist, this.customFieldsComp, this.section, this.bkngFormServ.stepWiseCustGrpPos['step_two']);
					if(this.customerComp){
						this.customerComp?.refresh();
					}
					// Adjust behavior of payment gateway child component based on selected card type
					if(this.BKFrm.controls['payment_method'].value == 'new_credit_card'){
						this.paymentGatewayChild = this.paymentInfo?.paymentGatewayChild;
						if (this.paymentGatewayChild) {
							this.paymentGatewayChild.isZipcode = true;
							this.paymentGatewayChild.refresh();
						}
					}
					this.cDRef.detectChanges();
					this.loader.hide()
				}
			} else {
				this.loader.hide()
			}
		}, 500);
	}
	/**
	 * Generate the payment token
	 * @param result
	 */
	public async generateToken(BKFrm: any) {
		this.paymentGatewayChild = this.paymentInfo?.paymentGatewayChild;
		let paymentData: {amount: number, location_id: number, base_location_id: number, payment_method: string, billing_address: BillingAddress} = {
			amount: +BKFrm?.value?.final_amount,
			location_id: +BKFrm?.value?.base_location_id,
			base_location_id: +BKFrm?.value?.base_location_id,
			payment_method: BKFrm?.value?.payment_method,
			billing_address: BKFrm?.value?.credit_card?.billing_address
		}
		// Calls the payment gateway child component's getPaymentToken method to generate the token.
		let paymentGatewayToken: PaymentGatewayPayload = await this.paymentServ?.generatePaymentGatewayToken(paymentData, this.paymentGatewayChild, true);
		if(paymentGatewayToken?.token || paymentGatewayToken?.dataValue){
			if(this.initServ.paymentGateway === 'authorizedotnet'){
				BKFrm.controls['credit_card'].get('dataValue').setValue(paymentGatewayToken.dataValue);
				BKFrm.controls['credit_card'].get('dataDescriptor').setValue(paymentGatewayToken.dataDescriptor);
			} else {
				BKFrm.controls['credit_card'].get('token').setValue(paymentGatewayToken?.token);
			}
			BKFrm.controls['pay_with_cc'].setValue(null);
			if(this.initServ.paymentGateway !== 'square'){
				this.setSendCCLink(BKFrm);
			}
			let bkngPayload: any = this.bkngCmnFun.bkngPaymentPayload(BKFrm.value, paymentGatewayToken);
			this.saveBooking(bkngPayload);
		}else{
			this.toastr.error('Please fill in the valid card details.');
			this.loader.hide()
		}
	}

	/**
	 * Set send_credit_card_link value
	 * @param BKFrm Booking form data
	 */
	private setSendCCLink(BKFrm: any): void {
		if(BKFrm.controls['send_credit_card_link'].value){
			BKFrm.controls['send_credit_card_link'].setValue(1);
		} else{
			BKFrm.controls['send_credit_card_link'].setValue(0);
		}
	}

	/*
	 * Function to save the booking
	 */
	saveBooking(bookingData: any){
		// Add contact to lead
		this.addContactToLead(1, true);
		if(bookingData.customer.emails && (bookingData.customer.emails).length > 0){
			for(let email of bookingData.customer.emails){
				email.value = (email.value).toLowerCase();
			}
		}
		bookingData['ip_address'] = bookingData.ip_address ? bookingData.ip_address : '';
		// Function to  check minimum limit for booking
		// let chkminLimitData: any = {
		// 	selectedService : this.selectedServiceType,
		// 	selectedFrequency : this.selectedFrequency,
		// 	prefilledData : this.prefilledData,
		// 	bookingType : this.bookingType
		// }
		// let flag : boolean = this.bkngCmnFun.chkMinimumLimit(bookingData, this.BKFrm, this.priceLocalVar, chkminLimitData);
		// if(flag){
			let queryParams : any;
			let headerParams : any;
			if (this.currentUser) {
				queryParams = {ses_id: this.ses_id, campaign_id: this.campaign_id, contact_id: this.contact_id, referrer_source: this.referrerSource, track_from: this.track_from, sequence_id: this.sequence_id};
			} else{
				headerParams = {'Content-Type': 'application/json', Ip: this.initServ.ipAddress};
			}
			// Remove "custom_fields_checkbox" control
			delete bookingData['custom_fields_checkbox'];
			this.apiServ.callApiWithQueryParams('POST', 'SaveBooking', queryParams, bookingData, headerParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.checSaveBookingResponse(res, bookingData));
		// }else{
		// 	this.reAssignParkingValue()
		// 	this.loader.hide()
		// 	this.bkngFormServ.scrollParentToTop()
		// }
		// }
	}
	/*
	 * Function to check the response of saveBooking api.
	 */
	private checSaveBookingResponse(res: any, bookingData: any = null): void {
		if(this.apiServ.checkAPIRes(res)){
			this.bkngSuccessRes(res);
		}else{
			// api_status 2 case of 3DS authorization(Card hold case)
			if(res && res?.api_status == 2 && res?.data){
				this.authorizationStatus(res, bookingData);
			} else {
				if(res?.data && res.data != 99){
					this.BKFrm.controls['booking_date'].setValue(null);
					this.BKFrm.controls['arrival_date'].setValue(null);
					this.BKFrm.controls['arrival_time'].setValue('');
					if(this.formLayout == 'multi_step'){
						this.moveToStep('step_three');
					}
				}
				this.reAssignParkingValue();
				if(res && res?.message){
					this.toastr.error(res.message);
				}
				this.loader.hide()
			}
		}
	}
	/**
	 * Authorization booking in case of 3DS card hold
	 * @param bkngRes: booking api res
	 */
	private async authorizationStatus(bkngRes: any, bookingData: any): Promise<void>{
		if(bookingData.payment_method == 'new_credit_card'){
			bookingData['stripe'] = this.paymentGatewayChild?.stripe;
		}
		let authStatusApiRes: any = await this.paymentServ?.authorizationStatus(bkngRes, bookingData);
		if(authStatusApiRes){
			this.authorizationApiRes(authStatusApiRes, bkngRes)
		}
	}
	/**
	 * Authorization status api res(3DS card hold)
	 * @param res: api res
	 * @param bkngRes: booking api res
	 */
	private authorizationApiRes(res: any, bkngRes: any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			let toastTime: boolean = false;
			if(res.data.status && res.data.status == "failed"){
				toastTime = true;
			}
			this.bkngSuccessRes(res, toastTime);
		} else {
			this.bkngSuccessRes(bkngRes);
		}
	}
	/**
	 * Booking success res
	 * @param res: api res
	 */
	private bkngSuccessRes(res: any, toastTime:boolean = false): void {
		this.bkngFormServ.scrollParentToTop()
		//code to show confirmation msg.
		if(this.utilServ.inIframe(this.utilServ.embedStatus)){
			this.loader.hide()
		}
		// Code for google analytics.
		this.googleTracking(res)
		//reset the selected email of short form version email.
		this.bkngFormServ.setSelectedEmailForm = '';
		/** Remove the local storage data for referrer_url. **/
		try{
			localStorage.removeItem("referrer_url")
		// eslint-disable-next-line no-empty
		}catch(err){}
		this.redirectAfterSuccess(res, toastTime);
	}
	/**
	* Redirect the customer on page where admin want to send after booking addition.
	* Reschedule page, thank you pages, any existing page or custom URL
	*/
	public redirectAfterSuccess(res: any, toastTime: boolean = false){
		let redirectTo = 'reschedule';
		let redirectUrl: any;
		if(this.formSett && this.formSett.where_should_redirect && this.formSett.redirect_val){
			redirectTo = this.formSett.where_should_redirect;
			redirectUrl = this.formSett.redirect_val;
		}
		/** Toaster for success **/
		this.toastr.success(res.message);
		// Authorization case(card hold) incease the time
		let timeOut: any = toastTime ? 6000 : 3000;
		setTimeout(()=>{
			let first_name = this.BKFrm.value.customer.first_name;
			let last_name = this.BKFrm.value.customer.last_name;
			let email = this.BKFrm.value.customer.email_id;
			let phone = this.BKFrm.value.customer.phone_number;
			let occurrence = this.BKFrm.value.occurrence;
			let refCode = (res.data.referral_code) ? res.data.referral_code : '';
			let uId = res.data.user_id;
			let gotoLinkUrl: any = null;
			this.loader.hide()
			switch (redirectTo) {
				case "link":
					// eslint-disable-next-line no-case-declarations
					let linkUrl: any = this.utilServ.checkHttpExist(redirectUrl);
					this.redirect(linkUrl, res.data);
					break;
				case "page":
					// eslint-disable-next-line no-case-declarations
					let slug = redirectUrl.replace(/\//g, "");
					switch (redirectUrl){
						case "/booknow":
						case "booknow":
							if(this.noOfIndustries && this.noOfIndustries > 0){
								gotoLinkUrl = null;
								this.popupServ.industriesPopup();
							} else{
								gotoLinkUrl = '/' + this.initServ.appDynamicRoutes['booknow'];
							}
						break;
						case "/gift-cards/send":
						case "/gift-cards":
						case "gift-card":
							// eslint-disable-next-line no-case-declarations
							let gcUrl;
							if(this.currentUser && !this.initServ.theme){
								gcUrl = '/gift-cards';
							} else {
								gcUrl = '/' + this.initServ.appDynamicRoutes['gift-card'];
							}
							gotoLinkUrl = gcUrl;
						break;
						case "/referrals":
						case "referrals":
							// eslint-disable-next-line no-case-declarations
							let refUrl = '/';
							if(!this.initServ.theme){
								refUrl = '/'+this.initServ.appDynamicRoutes['referrals']
							}
							gotoLinkUrl = refUrl;
						break;
						default:
							// eslint-disable-next-line no-case-declarations
							let url = (slug.charAt(0) == '/') ? (slug.substr(1)) : slug;
							if(url.charAt(0) !== '/' && this.initServ.appDynamicRoutes[url]){
								url = this.initServ.appDynamicRoutes[url]
							}
							// eslint-disable-next-line no-case-declarations
							let gotoUrl = (url.charAt(0) !== '/') ? ('/'+url) : url;
							gotoLinkUrl = gotoUrl;
						break;
					}
					if(gotoLinkUrl){
						this.redirect(this.utilServ.generateLink(gotoLinkUrl+"?b_id="+res.data.booking_id+"&token="+res.data.password_reset_token+"&first=true&isbooked=true&first_name="+first_name+"&last_name="+last_name+"&email="+email+"&phone="+phone+"&occurrence="+occurrence+"&ref_code="+refCode+"&u_id="+uId), res.data);
					}
					break;
				default:
					if(this.utilServ.inIframe(this.utilServ.embedStatus)){
						this.redirectUser(res.data);
					} else{
						if(this.currentUser){
							let formId = +this.formId;
							top.window.location.href = this.utilServ.generateLink("/reschedule-booking/"+this.industryId+"/"+formId+"/"+res.data.booking_id);
						} else{
							try{
								top.window.location.href = this.utilServ.generateLink("/"+this.initServ.appDynamicRoutes['reset-password']+"?token="+res.data.password_reset_token+"&first=true&isbooked=true&first_name="+first_name+"&last_name="+last_name+"&email="+email+"&phone="+phone+"&occurrence="+occurrence+"&ref_code="+refCode+"&u_id="+uId);
								// top.window.location.href = this.utilServ.generateLink("/reset-password?token="+res.data.password_reset_token+"&first=true&isbooked=true&first_name="+first_name+"&last_name="+last_name+"&email="+email+"&phone="+phone+"&occurrence="+occurrence+"&ref_code="+refCode+"&u_id="+uId);
							} catch(err){
								this.loader.hide()
								this.bookingCnfrmPopup(res.data)
							}
						}
					}
					break;
			}
		},timeOut);
	}
	/**
	* Change the browser url to desired url and open popup if the url is not correct.
	*/
	public redirect(link: any, data: any){
		try{
			top.window.location.href = link;
		}
		catch(err){
			this.bookingCnfrmPopup(data);
		}
	}
	/**
	 * Function used to redirect user.
	 */
	private redirectUser(data: any){
		let link = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname)+"/"+this.initServ.appDynamicRoutes['reset-password']+"?token="+data.password_reset_token+"&first=true&isbooked=true";
		if(this.utilServ.inIframe(this.utilServ.embedStatus)){
			link = link+"&fromEmbed=true";
		}
		if(this.currentUser){
			link = window.location.protocol + '//' + (IS_DEV ? DEV_HOST : window.location.hostname)+"/dashboard";
			//Redirect to panel from embed, if old local storage is not exists
			let embedUrl: any = this.authServ.redirectFromEmbed();
			if(embedUrl){
				link = embedUrl;
			}
		}
		try{
			top.window.location.href = link;
		} catch(err){
			this.bookingCnfrmPopup(data)
		}
	}
	/**
	 * Open booking for confirmation on booking save.
	 */
	bookingCnfrmPopup(data: any){
		let obj = {
			currentUser: this.currentUser,
			industryId: this.industryId,
			formId: +this.formId,
			response: data
		}
		this.addBkngServ.bkngAddedSuccMsgPopup(obj);
	}
	/* function to reset parking value */
	reAssignParkingValue(){
		let parkingGroup = <FormGroup>this.BKFrm.controls['parking'];
		if(parkingGroup.controls['total_amount'].value){
			let parking = parkingGroup.controls['total_amount'].value;
			parkingGroup.controls['total_amount'].setValue(parking.toString());
		}
	}

	/**
	 * Go to real booking form
	 * Used this function in case of short form only
	 */
	public gotoBookNow(): void {
		this.bkngFormServ.setShortFormData = this.BKFrm.value;
		this.router.navigate(['/'+this.initServ.appDynamicRoutes['booknow']+'/'+this.industrySlug]);
		this.dialogRef.closeAll();
	}

	/* function for google tracking codes */
	googleTracking(response:any){
		if(typeof(gtag) !== 'undefined'){
			try{
				gtag('event', 'BookingByCustomer', {'booking_id': response?.data?.booking_id});
			}
			catch(err){
				console.log("Google event conversion error", err)
			}
		}
	}

	/* load gtag library */
	checkGtag(){
		if(typeof(gtag) === 'undefined'){
			this.utilServ.loadGtag();
		}
	}
	/**
	 * Adds contact information to a lead.
	 * @param {number | null} interval - Optional interval for submitting data (default: -1).
	 */
	public addContactToLead(interval: number | null = null, isSubmit = false): void {
		this.leadsServ.addContactToLead(this.BKFrm, { type: 'booking', slug: 'booking-form' }, interval, isSubmit);
	}
	/**
	 * Handles the focus out event for customer details.
	 * This function adds the customer details to a campaign and a lead.
	 */
	public custDetailsFocusOut(): void {
		this.addBkngServ.addContactToCampaign(this.BKFrm.value);
		this.addContactToLead();
	}
	/**
	 * Check accept terms and conditions status based on theme builder data
	 */
	private setAcceptTcStatus(): void {
		let data: any = this.pageSett[this.section?.accept_tc?.id];
		// eslint-disable-next-line no-unsafe-optional-chaining
		if(data?.status && data?.display_on && (data?.display_on).length > 0 && (data?.display_on).includes('customer')){
			this.acceptTcStatus = true;
			this.setTCDefVal(data);
		}
	}
	/**
	 * Sets the default value for the Terms and Conditions checkbox.
	 * @param data: any
	 */
	private setTCDefVal(data: any): void {
		if(!(data.required_for && (data.required_for).length > 0 && (data.required_for).includes('customer'))){
			this.BKFrm.controls['accept_terms_conditions'].setValue(true);
		}
	}
	/**
	 * Check accept terms and conditions valid status based on builder data
	 * @returns Boolean (True/false)
	 */
	private checkAcceptTcValid(): boolean {
		let data: any = this.pageSett[this.section?.accept_tc?.id];
		if(this.acceptTcStatus && data.required_for && (data.required_for).length > 0 && (data.required_for).includes('customer')){
			if(!this.BKFrm.controls['accept_terms_conditions'].value){
				return false;
			}
		}
		return true;
	}
	private getCustomFields(): void {
		let reqData: any = {
			industry_id: +this.industryId,
			form_id: +this.formId
		}
		if(this.bookingType == 'draft' && this.quoteId){
			reqData['draft_id'] = +this.quoteId
		}
		if(this.isShortFormOpen){
			reqData['step_one'] = true;
		}
		this.bkngCustSecServ.getCustomFields(this.BKFrm, this.cDRef, reqData, this.bookingType);
	}
	/**
	 * Sets up the steps for a multi-step form based on certain conditions.
	 */
	private setMultiStepFormSteps(): void {
		// Check if the form layout is 'multi_step'
		if(this.formLayout == 'multi_step'){
			this.isStepOneAvail = false;
			// Check if the form ID is 5
			if(+this.formId == 5){
				// For form ID 5, check conditions for step two and set steps accordingly
				if(this.checkSecOnStep(this.multiStepFormSections['step_two'], 'step_two')){
					// If conditions for step two are met, set all steps
					this.multistepFormSteps = ["step_one", "step_two", "step_three", "step_four"];
					this.isStepAvailFormFive = true;
				}else{
					// If conditions for step two are not met, skip step one
					this.multistepFormSteps = ["step_one", "step_three", "step_four"];
					this.isStepAvailFormFive = false;
				}
			}else{
				// For other form IDs, check conditions for step one and set steps accordingly
				let stepSeq: string[] = this.multiStepFormSections['step_one'];
				if(this.locationLayout != 'no_location' || stepSeq.includes('email') || this.hasMultipleServiceCat() || this.checkSecOnStep(stepSeq, 'step_one')){
					// If conditions for step one are met, set all steps
					this.multistepFormSteps = ["step_one", "step_two", "step_three", "step_four"];
					this.isStepOneAvail = true;
				}else{
					// If conditions for step one are not met, skip step one and refresh the slider
					this.multistepFormSteps = ["step_two", "step_three", "step_four"];
					this.addBkngServ.refreshMultiStepSlider();
					this.isStepOneAvail = false;
				}
				this.cDRef.detectChanges();
			}
		}
	}
	/**
	 * Checks if certain sections are present on the given step sequence.
	 * @param {string[]} stepSeq - The sequence of steps to check for section presence.
	 * @param {string} step - The current step being evaluated.
	 * @returns {boolean} - A boolean indicating whether certain sections are present on the step sequence.
	 */
	private checkSecOnStep(stepSeq: string[], step: string): boolean {
		if(stepSeq.includes('customer_details') || stepSeq.includes('payment_info')){
			return true;
		}else if(this.hasMultipleFreq(stepSeq)){
			return true;
		}else if(this.addBkngServ.checkKeyInfoSpecialNoteOnMultiStep(stepSeq, this.BKFrm, this.section, this.pageSett)){
			return true;
		}else if(this.hasExtras(stepSeq)){
			return true;
		}else if(this.stepHasCustFields(stepSeq, step)){
			return true;
		}else{
			return false;
		}
	}
	/**
	 * Checks if multiple frequencies are available based on the provided step sequence.
	 * @param {string[]} stepSeq - The sequence of steps to check for the presence of 'frequency'.
	 * @returns {boolean} - A boolean indicating whether multiple frequencies are available.
	 */
	private hasMultipleFreq(stepSeq: string[]): boolean {
		if(stepSeq.includes('frequency') && this.bkngFormServ.isFrequencyAvailable(this.settings.frequencies, this.selectedServiceType, this.selectedLocation, this.prefilledData, this.settings, this.bookingType)){
			return true;
		}
		return false;
	}
	/**
	 * Checks if extras are available based on the provided step sequence.
	 * @param {string[]} stepSeq - The sequence of steps to check for the presence of 'extras'.
	 * @returns {boolean} - A boolean indicating whether extras are available.
	 */
	private hasExtras(stepSeq: string[]): boolean {
		if(stepSeq.includes('extras') && this.bkngFormServ.isExtrasAvailable(this.settings.extras, this.selectedLocation, this.selectedServiceType, this.selectedFrequency, this.BKFrm.value, this.prefilledFormParams?.extras, this.settings, this.bookingType) && this.secServ.checkEleStatus(this.pageSett, this.section?.extras?.id)){
			return true;
		}
		return false;
	}
	/**
	 * Checks if the current step has any custom fields based on the provided step sequence.
	 * @param {string[]} stepSeq - The sequence of steps to check for the presence of custom fields.
	 * @param {string} step - The current step being evaluated.
	 * @returns {boolean} - A boolean indicating whether the current step has custom fields.
	 */
	private stepHasCustFields(stepSeq: string[], step: string): boolean {
		if(stepSeq.some((element: string) => element.includes('custom_')) && this.utilServ.checkArrLength(this.bkngCustSecServ.stepWiseCustGrp[step])){
			return true;
		}
		return false;
	}
	/**
	 * Checks if there are multiple service categories available.
	 * @returns {boolean} - A boolean indicating whether there are multiple service categories available.
	 */
	private hasMultipleServiceCat(): boolean {
		if((this.bkngFormServ.isServiceAvailable(this.settings.service_category, this.selectedLocation, this.prefilledData, this.settings, this.bookingType)) || this.bkngFormServ.isServiceAvailable(this.settings.service_category, this.selectedLocation, this.prefilledData, this.settings, this.bookingType, true)){
			return true;
		}
		return false;
	}
	private addRemoveStepWiseValOnCustFieldsWithDelay(): void {
		setTimeout(() => {
			this.bkngCustSecServ.addRemoveStepWiseValOnCustFields(true, this.bkngCustSecServ.getStep(this.activeStep));
		}, 150);
	}
	/**
	 * Function to change override each provider time for selected providers.
	 */
	public changeOverrideEachPrvdrTime(): void {
		this.overridePrvdrTimeServ.changeOverrideEachPrvdrTime(this.BKFrm, this.selectedServiceType);
		this.calcTotalPrice();
	}
	/**
	 * Function to called on when provider array is changed.
	 * Then reset the value of override_each_prvdr_time and reset each_prvdr_time array
	 */
	public resetPrvdrOverriddenTime(): void {
		let prvdrSetts = this.admnStngs?.merchant_settings?.providers;
		if(!prvdrSetts?.prvdr_change_override_time_behavior || prvdrSetts?.prvdr_change_override_time_behavior == "keep"){
			let copyOverrideTimeObj = JSON.parse(JSON.stringify(this.BKFrm?.value?.each_prvdr_time));
			this.overridePrvdrTimeServ.keepOverrideEachPrvdrTime(this.BKFrm, copyOverrideTimeObj);
			this.calcTotalPrice();
		}else{
			this.BKFrm.controls['override_each_prvdr_time'].setValue(false);
			this.changeOverrideEachPrvdrTime();
		}
	}

	/**
	 * Get customer details(Profile, addresses and cards) using the user ID (uid) if available,
	 * updates the local customerDetails property, and triggers change detection.
	 * TODO: Any
	 */
	private async getCustomerDetailsApi(): Promise<any> {
		if(this.BKFrm.controls?.['uid'].value){
			this.customerDetails = await this.bkngCmnFun.getCustomerDetailsApi(this.BKFrm.controls['uid'].value);
			this.cDRef.detectChanges();
		}
	}
}
